import SmoothScroll from "smooth-scroll";
import { useState, useEffect } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const BackToTop = () => {
    const [showBackToTop, setShowBackToTop] = useState(false);
    const scroll = new SmoothScroll();

    const handleBackToTop = () => {
        // Remove the hash from the URL without reloading the page
        window.history.replaceState(null, document.title, window.location.pathname + window.location.search);
        
        // Scroll to top with faster, consistent speed
        scroll.animateScroll(0, null, { 
            speed: 400,  // Reduced from 1000 to make it faster
            speedAsDuration: true,
            easing: 'easeOutQuad', // Add easing function for smoother acceleration
            offset: 80
        });
    };

    
    useEffect(() => {
        const handleScroll = () => {
            // Show the back to top button when scrolled down 300px
            if (window.scrollY > 300) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <div className="back-to-top-button-container">

        {showBackToTop && 
            <button 
                className="back-to-top-button" 
                onClick={handleBackToTop}
                aria-label="Back to top"
            >
                <KeyboardArrowUpIcon />
            </button>
        }
        </div>

    );
};

export default BackToTop;