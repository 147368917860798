// scene showing my twitter search tool
// copilot: create a search bar and a button

import React, { useEffect, useState, useRef, useContext } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchPreviewNew from '../components/SearchPreviewNew';
import { makeTwitterSearchLink, cleanCorporateName } from '../functions/TwitterSearch';
import CloseIcon from '@mui/icons-material/Close';
import DateRange from '../components/DateRange';
import { useAuth } from '../components/AuthContext';
import supabase from '../components/supabaseClient';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import TwitterSavedSearches from './TwitterSavedSearches';

const TwitterSearchTool = ({
    includeTagline=false, allowSavedSearches=false, hideAdvanced=false, allowAdvanced=false,
    placeholderText=''
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showTimeDropdown, setShowTimeDropdown] = useState(false);
    const [position, setPosition] = useState([0, 0]);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [onlyShowCompanySearch, setOnlyShowCompanySearch] = useState(false);
    const [timeDropdownText, setTimeDropdownText] = useState('Past Day');
    const defaultTimeValue = 0;
    const [timeValue, setTimeValue] = useState(defaultTimeValue);
    const [twitterLink, setTwitterLink] = useState('');
    const [showSavedSearches, setShowSavedSearches] = useState(false);
    const [isSaved, setIsSaved] = useState(false);    
    const [searches, setSearches] = useState([]);
    const [activeSavedSearchId, setActiveSavedSearchId] = useState(null);

    // These track the full json struct for the search details. 
    // Allows us to compare the current search details to the saved search details (if it exists)
    const [searchDetails, setSearchDetails] = useState(null);
    const [savedSearchDetails, setSavedSearchDetails] = useState(null);
    const [searchHasBeenChangedVsSaved, setSearchHasBeenChangedVsSaved] = useState(false);

    const [showNameModal, setShowNameModal] = useState(false);
    const [searchName, setSearchName] = useState('');


    const [corporateName, setCorporateName] = useState('');
    const [ticker, setTicker] = useState('');

    const { userId, ip, isMobile } = useAuth();

    const [socialFilter, setSocialFilter] = useState(false);
    const [followersOnly, setFollowersOnly] = useState(false);

    const [controlLink, setControlLink] = useState('');

    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const searchBoxRef = useRef(null);
    const inputRef = useRef(null);

    const todayDate = new Date();
    // date in yyyy-mm-dd format
    const todayDateString = todayDate.toISOString().split('T')[0];
    const [dateMin, setDateMin] = useState(null);
    const [dateMax, setDateMax] = useState(null);
    const [blockHashtags, setBlockHashtags] = useState(true);
    const [blockLinks, setBlockLinks] = useState(false);
    const [onlyPopularPosts, setOnlyPopularPosts] = useState(true);
    const [banList, setBanList] = useState([]);
    const [banListString, setBanListString] = useState('');

    const handleSearch = () => {
        if (twitterLink) {
            logSearch();
            window.open(twitterLink, '_blank');
        }
    }

    const handleOptionsClick = (event) => {
        event.stopPropagation();
        setShowTimeDropdown(prevState => !prevState);

        if (!showTimeDropdown) {
            // Only set position if we're opening the dropdown
            const button = buttonRef.current;
            if (button) {
                const rect = button.getBoundingClientRect();
                // handle scroll position
                const scrollY = window.scrollY;
                setPosition([rect.x, rect.y + scrollY]);
            }
        }
    }


    const handleOptionClicked = ({value}) => {
        console.log('option clicked', value);
        setShowTimeDropdown(false);
        setTimeValue(value);
        if (value===-1){
            console.log('custom option clicked');
            setShowTimeDropdown(false);
            if (allowAdvanced) {
                setShowAdvanced(true);
            }
            else {
                window.location.href = '/advanced-feature-pricing';
            }
        }
    }

    const handleEnterSearchTerm = () => {
        setIsInputFocused(false);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                buttonRef.current && !buttonRef.current.contains(event.target)) {
                setShowTimeDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    const handleSearchPreviewClick = ({company}) => {
        console.log('search preview clicked');
        console.log('company: ', company);
        const name = cleanCorporateName(company.name);
        const nameParts = name.split(' ');
        const nameQuery = nameParts.join(' OR ');
        const query = `$${company.ticker} OR "(${nameQuery})"`; 
        setSearchTerm(query);
        setIsInputFocused(false);
    }

    const handleInputFocus = () => {
        setIsInputFocused(true);
    }

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsInputFocused(false);
        }, 200);
    }

    useEffect(() => {
        // when the value changes, change the time dropdown text
        // today minus timeValue is the min date

        // console.log('new date:', newDateString);
        // setDateMin(newDateString);
        switch (timeValue) {
            case 0:
                setTimeDropdownText('All Time');
                setDateMin(null);
                break;
            case 1:
                setTimeDropdownText('Past Day');
                break;
            case 7:
                setTimeDropdownText('Past Week');
                break;
            case 30:
                setTimeDropdownText('Past Month');
                break;
            case 365:
                setTimeDropdownText('Past Year');
                break;
            default:
                setTimeDropdownText('Custom Range');
                break;
        }
    }, [timeValue]);

    const onEnter = (event) => {
        if (event.key === 'Enter' && isInputFocused && !dropdownRef.current) {
            console.log('searching via enter key...');
            console.log('search link:', twitterLink);
            handleSearch();
        }
    }

    useEffect(() => {
        setControlLink('https://x.com/search?q=' + searchTerm+'&f=live');
    }, [searchTerm]);

    function makeLink() {
        let effectiveDateMin = dateMin;
        let effectiveDateMax = dateMax;

        if (timeValue > 0) {
            const newDate = new Date(todayDate.setDate(todayDate.getDate() - timeValue));
            const newDateString = newDate.toISOString().split('T')[0];
            effectiveDateMin = newDateString;
            effectiveDateMax = null;
        }

        const params = {
            searchTerm: searchTerm, 
            custom_ban_list: banList,
            date_min: effectiveDateMin, date_max: effectiveDateMax, 
            social_filter: socialFilter, followed_only: followersOnly,
            block_hashtags: blockHashtags, block_links: blockLinks,
            min_faves: onlyPopularPosts ? 10 : null
            // min_retweets: onlyPopularPosts ? 10 : null
            // min_replies: onlyPopularPosts ? 10 : null
        }
        return makeTwitterSearchLink(params);
    };

    const closeAdvanced = () => {
        setShowAdvanced(false);
        setDateMax(null);
        setDateMin(null);
        // setTimeValue(defaultTimeValue);
    }

    const logSearch = () => {
        // upsert to supabase twitter_searches table. Schema: {ip, query, user_id (optional)}
        const payload = {ip: ip, query: searchTerm, user_id: userId};
        console.log('logging search:', payload);
        supabase.from('twitter_searches').upsert(
            payload
        ).then((res) => {
            console.log('search logged:', res);
        }).catch((err) => {
            console.error('error logging search:', err);
        })
    }

    const handleBanListAdd = () => {
        setBanList([...banList, banListString]);
        setBanListString('');
    }

    const saveSearch = (name) => {
        // saved_searches: {search_params (json), type: 'Twitter', name}
        // also need to uspert to saved_search_roles: {search_id, user_id, role}
        // upsert to saved_searches, get the id, then upsert to saved_search_roles
        setIsSaved(true);
        const searchParams = constructSearchParams();

        const payload = {search_params: searchParams, type: 'Twitter', name: name, link: twitterLink};
        
        console.log('saving search:', payload);
        
        supabase.from('saved_searches').upsert(
            payload
        )
        .select()
        .then((res) => {
            console.log('search saved:', res);
            // upsert to saved_search_roles
            const searchId = res.data[0].id;
            console.log('search id:', searchId);
            
            setActiveSavedSearchId(searchId);
            setSearchName(name);
            
            const rolePayload = {search_id: searchId, user_id: userId, role: 'Owner'};
            
            supabase.from('saved_search_roles').upsert(
                rolePayload
            ).then((res) => {
                console.log('role saved:', res);
                fetchSearches();
            }).catch((err) => {
                console.error('error saving role:', err);
            })
        }).catch((err) => {
            console.error('error saving search:', err);
            setIsSaved(false);
        })
    }

    const handleClickSave = () => {
        if (!allowSavedSearches) {
            // navigate the user to /twitter-pricing
            window.location.href = '/advanced-feature-pricing';
            return;
        }
        if (isSaved && userId) {
            // if the search is already saved, then we want to delete the saved search
            deleteSavedSearch();
            setIsSaved(false);
            return;
        }

        setIsSaved(true);
        setSearchHasBeenChangedVsSaved(false);
        setSearchName(searchTerm);
        setShowNameModal(true);
        fetchSearches();

        
    }

    const handleSaveName = () => {
        setShowNameModal(false);
        saveSearch(searchName);
    }

    const closeSaveNameWithoutSaving = () => {
        setShowNameModal(false);
        setIsSaved(false);
    }

    const fetchSearches = async () => {
        if (!userId) return;
        const { data, error } = await supabase
            .from('saved_search_roles')
            .select('*, search_details:saved_searches(*)')
            .eq('user_id', userId);
        if (error) console.log('error', error);
        else {
            console.log('searches: ', data);
            // strip any symbols from the front of 'name' and sort alphabetically
            const sortedData = data.sort((a, b) => {
                return a.search_details.name.localeCompare(b.search_details.name);
            });
            setSearches(sortedData);
        }
    }

    useEffect(() => {
        fetchSearches();
    }
    , [userId])

    const constructSearchParams = () => {
        // This function constructs a json with all the search details based on the current state
        return {
            searchTerm: searchTerm,
            dateMin: dateMin, dateMax: dateMax,
            banList: banList, socialFilter: socialFilter, followersOnly: followersOnly,
            blockHashtags: blockHashtags, blockLinks: blockLinks, onlyPopularPosts: onlyPopularPosts,
            timeValue: timeValue
        }
    }

    const constructSearchDetails = (link) => {
        // This function constructs a json with all the search details based on the current state
        return {
            search_params: constructSearchParams(),
            name: searchName,
            link: link
        }
    }

    const destructSearchParams = (data) => {
        // This takes a json with all the search details and sets all the values accordingly
        setTwitterLink(data.link);
        setSearchName(data.name);
        setSearchTerm(data.search_params.searchTerm);
        setTimeValue(data.search_params.timeValue);
        setDateMin(data.search_params.dateMin);
        setDateMax(data.search_params.dateMax);
        setBanList(data.search_params.banList);
        setSocialFilter(data.search_params.socialFilter);
        setFollowersOnly(data.search_params.followersOnly);
        setBlockHashtags(data.search_params.blockHashtags);
        setBlockLinks(data.search_params.blockLinks);
        setOnlyPopularPosts(data.search_params.onlyPopularPosts);
        setShowSavedSearches(false);
    }


    const setActiveSearchToSaved = (data) => {
        if (!data) {
            // if data is null, then we're creating a new search
            console.log('creating new search');
            setIsSaved(false);
            setActiveSavedSearchId(null);
            setSearchTerm('');
            setDateMin(null);
            setDateMax(null);
            setBanList([]);
            setSocialFilter(false);
            setFollowersOnly(false);
            setBlockHashtags(true);
            setBlockLinks(false);
            setOnlyPopularPosts(true);
            setShowAdvanced(false);
            setShowSavedSearches(false);
            setTimeValue(defaultTimeValue);
            
            return;
        }
        // This function takes in a json of the search data and sets the search term and other parameters
        // schema: {search_params, name, link}
        console.log('setting search to saved:', data);
        destructSearchParams(data);
        setIsSaved(true);
        setActiveSavedSearchId(data.id);
        setShowAdvanced(true);
    }

    function areJsonTreesDifferent(savedSearchDetails, searchDetails) {
        // Helper function to compare two values
        function compareValues(value1, value2) {
          // If both values are objects (but not null), compare their properties
          if (typeof value1 === 'object' && value1 !== null && 
              typeof value2 === 'object' && value2 !== null) {
            return compareObjects(value1, value2);
          }
          // Otherwise, directly compare the values
          return value1 !== value2;
        }
      
        // Helper function to compare two objects
        function compareObjects(obj1, obj2) {
          const keys1 = Object.keys(obj1);
          const keys2 = Object.keys(obj2);
      
          // Check if the number of keys is different
          if (keys1.length !== keys2.length) {
            return true;
          }
      
          // Check if all keys from obj1 exist in obj2 and have the same values
          for (let key of keys1) {
            if (!obj2.hasOwnProperty(key)) {
              return true;
            }
            if (compareValues(obj1[key], obj2[key])) {
              return true;
            }
          }
      
          return false;
        }
      
        // Start the comparison
        return compareObjects(savedSearchDetails, searchDetails);
    }

    useEffect(() => {
        // if the active saved search id changes, set the search details to the saved search details
        if (activeSavedSearchId) {
            const savedSearch = searches.find(search => search.search_details.id === activeSavedSearchId);
            if (!savedSearch) return;
            console.log('saved search:', savedSearch);
            // schema to conform to: {search_params, name, link}
            const payload = {
                search_params: savedSearch.search_details.search_params,
                name: savedSearch.search_details.name,
                link: savedSearch.search_details.link
            }
            

            setSavedSearchDetails(payload);
        }
    }, [activeSavedSearchId, searches]);

    useEffect(() => {
        // if the search details JSON changes, then we once again need to compare it to the saved search details
        console.log('search details changed:', searchDetails, savedSearchDetails);
        if (searchDetails && savedSearchDetails) {
            console.log('checking if search has been changed vs saved');
            const isDifferent = areJsonTreesDifferent(savedSearchDetails, searchDetails);
            console.log('is different:', isDifferent);
            setSearchHasBeenChangedVsSaved(isDifferent);
        }
    }, [searchDetails, savedSearchDetails]);

    useEffect(() => {
        console.log('search params have changed');
        const link = makeLink();
        setTwitterLink(link);
        const newDetails = constructSearchDetails(link);
        setSearchDetails(newDetails);
    }, [searchTerm, dateMin, dateMax, banList, socialFilter, followersOnly, blockHashtags, blockLinks, onlyPopularPosts, searchName, timeValue]);

    const handleSaveNewVersion = () => {
        setSearchHasBeenChangedVsSaved(false);

        // upsert the saved ID with the new search details
        console.log('saving new version of search');
        const payload = {
            id: activeSavedSearchId,
            search_params: searchDetails.search_params,
            name: searchName,
            link: twitterLink
        }
        console.log('payload:', payload);
        supabase.from('saved_searches').upsert(
            payload
        ).then((res) => {
            console.log('new version saved:', res);
            fetchSearches();
        }).catch((err) => {
            console.error('error saving new version:', err);
            setSearchHasBeenChangedVsSaved(true);

        });
    }

    const deleteSavedSearch = () => {
        console.log('deleting search:', activeSavedSearchId);
        supabase.from('saved_searches').delete().eq('id', activeSavedSearchId)
        .then((res) => {
            console.log('search deleted:', res);
            setActiveSavedSearchId(null);
            fetchSearches();
        }).catch((err) => {
            console.error('error deleting search:', err);
        })
    }

    useEffect(() => {
        if (showNameModal && userId) {
            inputRef.current.focus();
        }
    }, [showNameModal, userId]);

    return (
        <div className='centered twitter-search-section'>
            <br/>            

            <div className='twitter-search-container'>
                    {/* <div className='twitter-search-header-container'>
                        <h1 className='twitter-search-header'> ScuttleSift </h1>
                        <h4 className='twitter-search-subheader'>by Scuttlebutt Research</h4>
                        {includeTagline &&
                            <div className='twitter-search-description'>
                                Search X / Twitter for investment news and analysis, (mostly) free from the crypto bots and day traders.
                            </div>
                        }

                    </div> */}

                <br />
                {!showSavedSearches && 
                    <div>
                        {allowSavedSearches &&
                            <div>
                                <div className={'saved-searches-button split' + (showSavedSearches ? '' : ' ssb-closed')} onClick={() => setShowSavedSearches(true)}>
                                    Saved Searches
                                    <KeyboardArrowDownIcon />
                                </div>
                                <br />
                            </div>
                        }   
                    
                        
                        <div className='centered '>
                            <input 
                                className='twitter-input'
                                type="text" 
                                ref={searchBoxRef}
                                placeholder={placeholderText ? placeholderText : (isMobile ? 'Search for a company, ticker, or topic!' : 'Try searching for a ticker, company name, or term!')}
                                value={searchTerm} 
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{textAlign: 'left'}}
                                onFocus={handleInputFocus} 
                                onBlur={handleInputBlur}
                                onKeyDown={onEnter}
                            />
                        </div>

                        {showAdvanced && allowAdvanced &&
                            <div className='advanced'>
                                <br/>
                                <div className='advanced-search'>
                                    <div className='split' >
                                        <h3> Advanced Search </h3>
                                        <button className='button-minimal' onClick={closeAdvanced}> 
                                            <CloseIcon />
                                        </button>
                                    </div>
                                    <p> Use <code>AND</code> and <code>OR</code> to refine search. Use quotes <code>"..."</code> to search for that exact phrase and use <code>(parentheses)</code> to group terms together.</p>
                                    <div className='break' />
                                    <p> Example: <code>($NVDA AND $AMD) OR "Nvidia CUDA"</code></p>
                                </div>
                                <br/>
                                <h3> Filter Options </h3>
                                {/* <div className='break' /> */}
                                <br/>
                                <div className='search-time-dropdown-container'>
                                    <button className='search-time-dropdown'
                                        ref={buttonRef}
                                        onClick={handleOptionsClick}
                                        >
                                        {/* options dropdown -  */}
                                        <div> {timeDropdownText} </div>
                                        <ArrowDropDownIcon/>
                                    </button>
                                </div>
                                {timeValue === -1 ?
                                    <div style={{width: isMobile ? "94%": "100%"}} >
                                        <DateRange setStartDate={setDateMin} setEndDate={setDateMax} vertical={isMobile}/>
                                    </div>
                                    :
                                    <div className='large-break' />
                                    // <br />
                                }

                                <div className='break' />
                                <div className="checkbox-section vertical">
                                    <label className="checkbox-container">
                                        <input type="checkbox" checked={blockHashtags} onChange={() => setBlockHashtags(!blockHashtags)} />
                                        Filter out hashtags <div className='rec-tag'>Recommended</div>
                                    </label>
                                    <label className="checkbox-container">
                                        <input type="checkbox" checked={onlyPopularPosts} onChange={() => setOnlyPopularPosts(!onlyPopularPosts)} />
                                        Only popular posts <div className='rec-tag'>Recommended</div>
                                    </label>
                                    <label className="checkbox-container">
                                        <input type="checkbox" checked={socialFilter} onChange={() => setSocialFilter(!socialFilter)} />
                                        Only trusted accounts
                                    </label>
                                    <label className="checkbox-container">
                                        <input type="checkbox" checked={followersOnly} onChange={() => setFollowersOnly(!followersOnly)} />
                                        Only accounts you follow
                                    </label>
                                    <label className="checkbox-container">
                                        <input type="checkbox" checked={blockLinks} onChange={() => setBlockLinks(!blockLinks)} />
                                        Filter out posts with links
                                    </label>
        
                                </div>
                                <br/>
                                <h3> Custom Blacklist </h3>
                                <div className='break' />

                                {/* <p> Add terms to exclude from search. For example, you might exclude <code>fruit</code> or <code>banana</code> from your searches about Apple Inc.</p> */}
                                {/* <div className='small-break' /> */}
                                <div className='banlist-section'>
                                    <div className='banlist-inputs' >
                                        <input className='exclude-input' type="text" 
                                            placeholder='Add a term to exclude' 
                                            value={banListString} 
                                            onChange={(e) => setBanListString(e.target.value)} 
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleBanListAdd();
                                                }
                                            }}
                                        />
                                        <button className='add-ban-button' onClick={handleBanListAdd}> <ControlPointIcon style={{fontSize: 24}} /> </button>
                                    </div>
                                    {/* Map over the banlist terms and show them */}
                                    <div className='small-break' />
                                    <div className='banlist'>
                                        {banList.map((term, index) => {
                                            return (
                                                <div key={index} className='banlist-item'>
                                                    <div> {term} </div>
                                                    <button className='button-minimal' 
                                                    style={{padding: '4px', width: 'inherit', height: 'inherit'}}
                                                    onClick={() => {
                                                        const newBanList = banList.filter((item, i) => i !== index);
                                                        setBanList(newBanList);
                                                    }}>
                                                        <CloseIcon style={{fontSize: 16}} />
                                                    </button>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>


                                <br/>
                            </div>
                        }
                            

                        <div className='search-options-buttons'>
                            {showAdvanced &&
                                <div style={{width: '100%'}}>
                                    {searchTerm.length > 0 && (!isSaved || !searchHasBeenChangedVsSaved) &&
                                        <div className='save-button-big'
                                            onClick={handleClickSave}
                                        >
                                            {isSaved ? <BookmarkAddedIcon /> : <BookmarkBorderIcon />}
                                            {isSaved ? 'Saved' : 'Save Search'}
                                        </div>
                                    }
                                    {searchHasBeenChangedVsSaved && isSaved &&
                                        <div className='split' style={{gap:'10px'}}>
                                            <button className='save-button-big'
                                                onClick={() => {
                                                    console.log('canceling changes');
                                                    destructSearchParams(savedSearchDetails);
                                                    setIsSaved(true);
                                                    setSearchHasBeenChangedVsSaved(false);
                                                }}
                                            >
                                                Cancel Changes
                                            </button>
                                            <button className='save-button-big'
                                                onClick={handleSaveNewVersion}
                                            >
                                                <BookmarkBorderIcon />
                                                Save New Version
                                            </button>
                                        </div>
                                    }
                                    <div className='break' />
                                    <button
                                        className={isMobile ? 'twitter-search-button mobile' : 'twitter-search-button wide'}
                                        onClick={handleSearch}
                                    >   
                                        Search
                                    </button>
                                </div>
                            }
                        </div>

                        {!showAdvanced &&
                        <div >
                            <br/>
                            <div className='search-options'>
                                <div>
                                    <button className='search-time-dropdown'
                                        ref={buttonRef}
                                        onClick={handleOptionsClick}
                                        >
                                        {/* options dropdown -  */}
                                        <div> {timeDropdownText} </div>
                                        <ArrowDropDownIcon/>
                                    </button>
                                </div>
                                <div className='search-options-buttons'>
                                    {searchTerm.length > 0 && 
                                        <div>
                                            {(!searchHasBeenChangedVsSaved || !isSaved) &&
                                                <div className='button-minimal'
                                                    onClick={handleClickSave}
                                                >
                                                    {isSaved ? <BookmarkAddedIcon /> : <BookmarkBorderIcon />}
                                                </div>
                                            }
                                            {isSaved && searchHasBeenChangedVsSaved &&
                                                <div className='save-cancel-small'>
                                                    {/* <div className='caption'>Changes Made</div> */}
                                                    <div className='button-gray'
                                                        onClick={() => {
                                                            console.log('canceling changes');
                                                            destructSearchParams(savedSearchDetails);
                                                            // setIsSaved(true);
                                                            // setSearchHasBeenChangedVsSaved(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </div>
                                                    <div className='button-gray'
                                                        onClick={handleSaveNewVersion}
                                                    >
                                                        Save
                                                    </div>
                                                </div>
                                                }
                                        </div>
                                    }
                                    {!hideAdvanced &&
                                        <div className='button-minimal'
                                            onClick={() => {
                                                if (allowAdvanced) {
                                                    setShowAdvanced(!showAdvanced);
                                                }
                                                else {
                                                    window.location.href = '/advanced-feature-pricing';
                                                }
                                            }}
                                        >
                                            <TuneIcon sx={{ fontSize: 24 }} />
                                        </div>
                                    }
                                    <button
                                        className='twitter-search-button standard'
                                        onClick={handleSearch}
                                    >   
                                        Search
                                    </button>
                                </div>

                            </div>

                        </div>
                        }

                        {showTimeDropdown &&
                            <div ref={dropdownRef} className='time-dropdown' style={{position: 'absolute', top: position[1] + 30, left: position[0]}}>
                                <button className='dropdown-button' onClick={() => handleOptionClicked({value: 1})}> Past Day </button>
                                <button className='dropdown-button' onClick={() => handleOptionClicked({value: 7})}> Past Week </button>
                                <button className='dropdown-button' onClick={() => handleOptionClicked({value: 30})}> Past Month </button>
                                <button className='dropdown-button' onClick={() => handleOptionClicked({value: 365})}> Past Year </button>
                                <button className='dropdown-button' onClick={() => handleOptionClicked({value: 0})}> All Time </button>
                                <button className='dropdown-button' onClick={() => handleOptionClicked({value: -1})}> Custom </button>
                            </div>
                        }

                        {/* {isInputFocused && searchTerm.length > 0 &&
                            <SearchPreviewNew 
                                query={searchTerm} 
                                parentRef={searchBoxRef} 
                                onlyShowCompanySearch={onlyShowCompanySearch} 
                                handleSearch={handleEnterSearchTerm}
                                handleClick={handleSearchPreviewClick}
                                previewTextOverride={'Search X.com for Scuttlebutt about "' + searchTerm + '"'}
                            />
                        } */}

                        <div className='large-break' />
                        <div className='centered'>
                            {searchTerm !=='' && <a href={controlLink} target='_blank' rel='noreferrer'> See what we spared you from! </a>}
                        </div>
                        <br/>
                        <br/>
                    </div>
                }

                {showSavedSearches &&
                    <div>
                        <div className='saved-searches-button'>
                            <div className='split' onClick={() => setShowSavedSearches(false)}>
                                <div />
                                <KeyboardArrowUpIcon />
                            </div>
                            <div className='small-break' />
                            <div className='saved-searches'>
                                <TwitterSavedSearches userId={userId} setSearch={setActiveSearchToSaved} searches={searches} />
                            </div>

                        </div>
                        <br/>
                    </div>
                }



            </div>

            {showNameModal && userId &&
                <div className='modal-overlay'>
                    <div className='modal'>
                        <div className='split'>
                            <h4> Save Search </h4>
                            <button className='button-minimal' onClick={closeSaveNameWithoutSaving}> <CloseIcon /> </button>
                        </div>
                        <div className='modal-body'>
                            <input 
                                className='twitter-input'
                                type="text" 
                                placeholder="Name your search"
                                ref={inputRef}
                                value={searchName} 
                                onChange={(e) => setSearchName(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSaveName();
                                    }
                                }}
                            />
                            <br/>
                            <br/>

                            <button className='twitter-search-button' style={{width:'100%'}} onClick={handleSaveName}> Save </button>
                        </div>
                    </div>
                </div>
            }

            {showNameModal && !userId &&
                <div className='modal-overlay'>
                    <div className='modal'>
                        <div className='split'>
                            <h4> Save Search </h4>
                            <button className='button-minimal' onClick={closeSaveNameWithoutSaving}> <CloseIcon /> </button>
                        </div>
                        <div className='modal-body'>
                            <p> You must be logged in to save searches. </p>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default TwitterSearchTool;

