import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import supabase from './supabaseClient';
import '../styles/VideoTranscript.css';

const VideoTranscript = ({ initialTimestamp, overrideId }) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [video, setVideo] = useState(null);
  const [transcript, setTranscript] = useState([]);
  const [player, setPlayer] = useState(null);
  const [processedTranscript, setProcessedTranscript] = useState([]);

  // Get timestamp from either prop or URL param
  const startTime = initialTimestamp || Number(searchParams.get('t')) || 0;
  // Use overrideId if provided, otherwise use id from URL params
  const videoId = overrideId || id;

  useEffect(() => {
    if (videoId) {
      fetchVideoData();
    }
  }, [videoId]);

  useEffect(() => {
    if (video) {
      // Load the IFrame Player API code asynchronously
      const tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Replace the 'ytplayer' element with an <iframe> when API is ready
      window.onYouTubeIframeAPIReady = () => {
        const newPlayer = new window.YT.Player('youtube-player', {
          height: '500',
          width: '100%',
          videoId: extractVideoId(video.url),
          playerVars: {
            playsinline: 1,
            start: Math.floor(startTime)
          },
          events: {
            onReady: (event) => {
              console.log('Player ready');
              setPlayer(event.target);
              // Seek to initial timestamp if it exists
              if (startTime > 0) {
                event.target.seekTo(startTime);
                event.target.playVideo();
              }
            },
            onStateChange: (event) => {
              console.log('Player state changed:', event.data);
            }
          }
        });
      };
    }
  }, [video, startTime]);

  useEffect(() => {
    if (transcript.length > 0) {
      processTranscript();
    }
  }, [transcript]);

  const processTranscript = () => {
    const processed = [];
    let currentParagraph = { text: '', sentences: [] };
    
    transcript.forEach((segment, index) => {
      // Split on sentence endings but preserve natural line breaks
      const sentences = segment.text
        .split(/(?<=[.!?])\s+/)
        .filter(s => s.trim());

      sentences.forEach((sentence) => {
        // Check if this segment starts with a clear new thought or speaker change
        const isNewParagraph = (
          /^[A-Z]/.test(sentence) && // Starts with capital letter
          (currentParagraph.sentences.length > 0) && // Not the first sentence
          (
            sentence.startsWith("But ") ||
            sentence.startsWith("So ") ||
            sentence.startsWith("Now ") ||
            sentence.startsWith("And ") ||
            sentence.includes(":") || // Likely a speaker change
            currentParagraph.sentences.length >= 4 // Natural break after several sentences
          )
        );

        if (isNewParagraph) {
          if (currentParagraph.text) {
            processed.push({ ...currentParagraph });
          }
          currentParagraph = { text: '', sentences: [] };
        }

        // Add sentence to current paragraph
        currentParagraph.text += (currentParagraph.text ? ' ' : '') + sentence;
        currentParagraph.sentences.push({
          text: sentence,
          timestamp: segment.start
        });
      });

      // Force paragraph break if segment ends with a clear break
      if (segment.text.endsWith('\n') || segment.text.endsWith('.')) {
        if (currentParagraph.text) {
          processed.push({ ...currentParagraph });
          currentParagraph = { text: '', sentences: [] };
        }
      }
    });

    // Add any remaining text as the last paragraph
    if (currentParagraph.text) {
      processed.push(currentParagraph);
    }

    setProcessedTranscript(processed);
  };

  const extractVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const fetchVideoData = async () => {
    try {
      const { data, error } = await supabase
        .from('videos')
        .select('*, transcript_json_clean')
        .eq('id', videoId)
        .single();

      if (error) throw error;

      setVideo(data);
      // Prefer clean transcript if available
      const transcriptJson = data.transcript_json_clean || data.transcript_json;
      if (transcriptJson) {
        setTranscript(JSON.parse(transcriptJson));
      }
    } catch (error) {
      console.error('Error fetching video data:', error);
    }
  };

  const handleSentenceClick = (timestamp) => {
    console.log('Attempting to seek to:', timestamp, 'Player:', player);
    if (player && player.seekTo) {
      try {
        player.seekTo(Math.floor(timestamp));
        player.playVideo();
        console.log('Seek successful');
      } catch (error) {
        console.error('Error seeking video:', error);
      }
    } else {
      console.log('Player not ready or seekTo not available');
    }
  };

  if (!video) {
    return <div>Loading...</div>;
  }

  return (
    <div className="video-transcript-container">
      <div className="video-section">
        <div id="youtube-player"></div>
      </div>

      <div className="transcript-section">
        {processedTranscript.map((paragraph, pIndex) => (
          <p key={pIndex} className="transcript-paragraph">
            {paragraph.sentences.map((sentence, sIndex) => (
              <span
                key={sIndex}
                className="transcript-sentence"
                onClick={() => handleSentenceClick(sentence.timestamp)}
              >
                {sentence.text}
                {sIndex < paragraph.sentences.length - 1 ? ' ' : ''}
              </span>
            ))}
          </p>
        ))}
      </div>
    </div>
  );
};

export default VideoTranscript; 