import React from 'react';

const CTSDateRange = ({ setStartDate, setEndDate }) => {
    return (
        <div className="cts-date-range">
            <input
                type="date"
                className="cts-date-input"
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
            />
            <input
                type="date"
                className="cts-date-input"
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
            />
        </div>
    );
};

export default CTSDateRange; 