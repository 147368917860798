import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import VideoTranscript from './VideoTranscript';
import supabase from './supabaseClient';
import '../styles/VideoSnippet.css';

const VideoSnippet = ({ 
  videoId, 
  videoTitle,
  startTime, 
  endTime, 
  transcriptText, // Optional override text
  displayMode = 'inline', // 'inline', 'dialog', or 'sidebar'
  showActionButton = true // Whether to show the "View full video" button
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snippetText, setSnippetText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!transcriptText) {
      fetchSnippetText();
    }
  }, [videoId, startTime, endTime, transcriptText]);

  const fetchSnippetText = async () => {
    try {
      const { data, error } = await supabase
        .from('videos')
        .select('transcript_json, transcript_json_clean')
        .eq('id', videoId)
        .single();

      if (error) throw error;

      // Prefer clean transcript if available
      const transcriptJson = data?.transcript_json_clean || data?.transcript_json;
      
      if (transcriptJson) {
        const transcript = JSON.parse(transcriptJson);
        const relevantSegments = transcript.filter(
          segment => segment.start >= startTime && segment.start <= endTime
        );
        
        // Process segments to create smoother text
        const processedText = relevantSegments
          .map(s => s.text.trim())
          .join(' ')
          // Remove multiple spaces
          .replace(/\s+/g, ' ')
          // Remove line breaks
          .replace(/\n+/g, ' ')
          // Fix spacing around punctuation
          .replace(/\s+([.,!?])/g, '$1')
          // Ensure proper spacing after punctuation
          .replace(/([.,!?])(\w)/g, '$1 $2')
          .trim();

        setSnippetText(processedText);
      }
    } catch (error) {
      console.error('Error fetching snippet text:', error);
    }
  };

  const formatTimestamp = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleSnippetClick = () => {
    if (displayMode === 'dialog') {
      setIsDialogOpen(true);
    } else {
      // For inline mode, open in new tab
      window.open(`/video/${videoId}?t=${startTime}`, '_blank');
    }
  };

  const renderContent = () => (
    <div className="video-snippet">
      <div className="video-snippet-header">
        <span className="video-snippet-title">{videoTitle}</span>
        <span className="video-snippet-timestamp">
          {formatTimestamp(startTime)} - {formatTimestamp(endTime)}
        </span>
      </div>
      <div 
        className="video-snippet-text"
        onClick={handleSnippetClick}
      >
        {transcriptText || snippetText || 'Loading...'}
      </div>
      {showActionButton && displayMode === 'inline' && (
        <Link 
          to={`/video/${videoId}?t=${startTime}`} 
          className="video-snippet-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          View full video →
        </Link>
      )}
    </div>
  );

  if (displayMode === 'dialog') {
    return (
      <>
        {renderContent()}
        <Dialog 
          open={isDialogOpen} 
          onClose={() => setIsDialogOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            <VideoTranscript 
              initialTimestamp={startTime}
              overrideId={videoId}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return renderContent();
};

export default VideoSnippet; 