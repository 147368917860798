import React from 'react';
import Login from '../scenes/Login';
import Modal from './Modal';

function LoginModal({loginModalIsOpen, closeLoginModal, header}) {
  return (
    <Modal
      isOpen={loginModalIsOpen}
      onClose={closeLoginModal}
      title={header}
    >
      <Login closeLoginModal={closeLoginModal}/>
    </Modal>
  );
}

export default LoginModal;