import React from 'react';

const ContentCard = ({ 
    title,
    subtitle,
    children,
    className = '',
    variant = 'card'
}) => {
    return (
        <div className="content-section">
            {title && (
                <div className="content-header">
                    <h2 className="content-title">{title}</h2>
                    {subtitle && <p className="content-subtitle">{subtitle}</p>}
                </div>
            )}
            {variant === 'card' ? (
                <div className={`card card-md ${className}`}>
                    <div className="card-content">
                        {children}
                    </div>
                </div>
            ) : (
                children
            )}
        </div>
    );
};

export default ContentCard; 