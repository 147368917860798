import React, { useEffect, useContext, useState } from "react";
import { useAuth } from '../AuthContext';
import SmoothScroll from 'smooth-scroll';
import { ModalContext } from '../../App';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export const Navigation = (props) => {
    const { userId, isMobile } = useAuth();
    const modalContext = useContext(ModalContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const scroll = new SmoothScroll('a[href*="#"]', {
            speed: 500,
            speedAsDuration: true,
            easing: 'easeInOutCubic',
            offset: 50
        });

        return () => {
            scroll.destroy();
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        document.body.style.overflow = !isMenuOpen ? 'hidden' : 'unset';
    };

    return (
        <>
            <div className="header-bar-container">
                <div className="header-bar landing-header">
                    {isMobile ? (
                        <>
                            <a className="navbar-title" href="#page-top">
                                <img src='/img/favicon-navbar.png' alt="Scuttlebutt Logo" style={{'width': '40px', 'height': '40px'}}/>
                            </a>
                            <div className="mobile-controls">
                                <button className="btn white square narrow mobile-demo-btn"
                                    onClick={() => window.location.href = '/book-demo'}
                                >
                                    Book Demo
                                </button>
                                <button className="hamburger-menu" onClick={toggleMenu}>
                                    <MenuIcon sx={{ color: 'white', fontSize: 28 }} />
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <a className="navbar-title" href="/">
                                <img src='/img/favicon-navbar.png' alt="Scuttlebutt Logo" style={{'width': '40px', 'height': '40px'}}/>
                                Scuttlebutt Research
                            </a>
                            <div className="navbar-button-container">
                                <div style={{ display: 'flex', gap: '8px' }}>
                                    <button className="btn-white-outline btn-narrow btn-square btn-md btn-bold"
                                        onClick={() => modalContext.openLoginModal(true)}
                                    >
                                        Sign In
                                    </button>
                                    <button className="btn-white btn-narrow btn-square btn-md btn-bold"
                                        onClick={() => window.location.href = '/book-demo'}
                                    >
                                        Book Demo
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Mobile Menu Overlay */}
            {isMenuOpen && (
                <div className="mobile-menu-overlay">
                    <div className="mobile-menu-content">
                        <div className="mobile-menu-header">
                            <img src='/img/favicon-navbar.png' alt="Scuttlebutt Logo" style={{'width': '40px', 'height': '40px'}}/>
                            <button className="close-menu" onClick={toggleMenu}>
                                <CloseIcon sx={{ color: 'white', fontSize: 28}} />
                            </button>
                        </div>

                        <nav className="mobile-menu-nav">
                            <a href="#about" onClick={toggleMenu}>About</a>
                            <a href="#industry-nps" onClick={toggleMenu}>Industry NPS</a>
                            <a href="#customer-switching" onClick={toggleMenu}>Customer Switching</a>
                            <a href="#pricing-insights" onClick={toggleMenu}>Pricing Insights</a>
                            <a href="#quote-search" onClick={toggleMenu}>Quote Search</a>
                            <a href="#philosophy" onClick={toggleMenu}>Philosophy</a>

                            <div className="mobile-menu-buttons">
                                <button className="btn white-outline square mobile-menu-btn"
                                    onClick={() => {
                                        toggleMenu();
                                        modalContext.openLoginModal(true);
                                    }}
                                >
                                    Sign In
                                </button>

                                <button className="btn white square mobile-menu-btn"
                                    onClick={() => window.location.href = '/book-demo'}
                                >
                                    Book Demo
                                </button>
                            </div>
                        </nav>
                    </div>
                </div>
            )}
        </>
    );
};