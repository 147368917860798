import React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

const TimeRangeSelector = ({ 
    timeRange,
    onTimeRangeChange,
    showLTM = false,
    onLTMChange,
    ltmSelected,
    periods = ['1y', '3y', '5y', '10y', 'all'], // Customizable periods
    defaultPeriod = '3y' // Default selected period
}) => {
    const handleTimeRangeChange = (event, newRange) => {
        if (newRange !== null) {
            onTimeRangeChange(newRange);
        }
    };

    return (
        <div className="time-range-selector">
            <ToggleButtonGroup
                value={timeRange || defaultPeriod}
                exclusive
                onChange={handleTimeRangeChange}
                aria-label="time range"
                size="small"
                className="time-range-toggle"
            >
                {periods.map(period => (
                    <ToggleButton key={period} value={period}>
                        {period === 'all' ? 'All' : period.toUpperCase()}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {showLTM && (
                <ToggleButton
                    value="ltm"
                    selected={ltmSelected}
                    onChange={() => onLTMChange(!ltmSelected)}
                    size="small"
                >
                    LTM
                </ToggleButton>
            )}
        </div>
    );
};

export default TimeRangeSelector;