import React from 'react';
import '../styles/AppV2.css';
import VideoSnippet from '../components/VideoSnippet';

import { 
  Search, 
  Bookmark, 
  Share, 
  Add, 
  Settings, 
  Delete,
  Edit,
  MoreVert,
  Close
} from '@mui/icons-material';

const UIKit = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-xl">
      <div className="container container-lg">
        <h1 className="text-3xl font-bold mb-xl">UI Kit</h1>
        
        {/* Video Snippets */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Video Snippets</h2>
          
          <div className="flex flex-col gap-lg">
            <h3 className="text-lg font-semibold mb-sm">Inline Video Snippet (with action button)</h3>
            <VideoSnippet
              videoId="IodbsQV8dFE"
              videoTitle="Example YouTube Video"
              startTime={60}
              endTime={120}
              displayMode="inline"
              showActionButton={true}
            />

            <h3 className="text-lg font-semibold mb-sm">Inline Video Snippet (without action button)</h3>
            <VideoSnippet
              videoId="IodbsQV8dFE"
              videoTitle="Example YouTube Video"
              startTime={120}
              endTime={180}
              displayMode="inline"
              showActionButton={false}
            />

            <h3 className="text-lg font-semibold mb-sm">Dialog Video Snippet</h3>
            <VideoSnippet
              videoId="IodbsQV8dFE"
              videoTitle="Example YouTube Video"
              startTime={180}
              endTime={240}
              transcriptText="This is manually provided text that overrides the auto-fetched transcript. Click to open the video in a dialog."
              displayMode="dialog"
            />
          </div>
        </section>

        {/* Typography */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Typography</h2>
          
          {/* Headings */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Headings</h3>
            <div className="flex flex-col gap-lg">
              <h1 className="text-4xl">Heading 1 - 48px Bold</h1>
              <h2 className="text-3xl">Heading 2 - 36px Bold</h2>
              <h3 className="text-2xl">Heading 3 - 30px Semibold</h3>
              <h4 className="text-xl">Heading 4 - 24px Semibold</h4>
            </div>
          </div>

          {/* Body Text */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Body Text</h3>
            <div className="flex flex-col gap-lg">
              <p className="text-lg">Large Text (18px) - Ideal for important body content or introductory paragraphs.</p>
              <p className="text-base">Base Text (16px) - The standard body text size, offering optimal readability for main content.</p>
              <p className="text-sm">Small Text (14px) - Used for secondary information, captions, or supporting text.</p>
              <p className="text-xs">Extra Small Text (12px) - Reserved for labels, footnotes, or very minor details.</p>
            </div>
          </div>

          {/* Font Weights */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Font Weights</h3>
            <div className="flex flex-col gap-md">
              <p className="text-base font-bold">Bold (700) - For strong emphasis</p>
              <p className="text-base font-semibold">Semibold (600) - For medium emphasis</p>
              <p className="text-base font-medium">Medium (500) - For slight emphasis</p>
              <p className="text-base font-normal">Regular (400) - For normal text</p>
            </div>
          </div>

          {/* Text Colors */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Text Colors</h3>
            <div className="flex flex-col gap-md">
              <p className="text-primary">Primary Blue - Used for links and primary actions</p>
              <p className="text-gray-900">Dark Gray - Primary text color</p>
              <p className="text-gray-700">Medium Gray - Secondary text color</p>
              <p className="text-gray-600">Light Gray - Tertiary text color</p>
            </div>
          </div>

          {/* Text Styles */}
          <div>
            <h3 className="text-lg font-semibold mb-md">Text Styles</h3>
            <div className="flex flex-col gap-md">
              <p className="italic">Italic text - For emphasis or quotes</p>
              <p className="uppercase">Uppercase text - For labels or headers</p>
              <p className="capitalize">Capitalized text - For titles or names</p>
            </div>
          </div>
        </section>

        {/* Buttons */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Buttons</h2>
          
          {/* Standard Buttons */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Standard Buttons</h3>
            <div className="p-lg bg-gray-50 rounded-lg">
              <div className="flex gap-md">
                <button className="btn btn-primary btn-square">Primary</button>
                <button className="btn btn-outline btn-square">Outline</button>
                <button className="btn btn-white btn-square">White</button>
              </div>
            </div>
          </div>

          {/* Rounded Buttons */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Rounded Buttons</h3>
            <div className="p-lg bg-gray-50 rounded-lg">
              <div className="flex gap-md">
                <button className="btn btn-primary btn-rounded">Primary</button>
                <button className="btn btn-outline btn-rounded">Outline</button>
                <button className="btn btn-white btn-rounded">White</button>
              </div>
            </div>
          </div>

          {/* Button Sizes */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Button Sizes</h3>
            <div className="p-lg bg-gray-50 rounded-lg">
              <div className="flex gap-md items-center">
                <button className="btn btn-primary btn-sm btn-square">Small</button>
                <button className="btn btn-primary btn-md btn-square">Medium</button>
                <button className="btn btn-primary btn-lg btn-square">Large</button>
              </div>
            </div>
          </div>

          {/* Button Widths */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Button Widths</h3>
            <div className="p-lg bg-gray-50 rounded-lg">
              <div className="flex flex-col gap-md">
                <button className="btn btn-primary btn-narrow btn-square">Narrow</button>
                <button className="btn btn-primary btn-wide btn-square">Wide</button>
                <button className="btn btn-primary btn-full btn-square">Full Width</button>
              </div>
            </div>
          </div>

          {/* Buttons with Icons */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Buttons with Icons</h3>
            <div className="p-lg bg-gray-50 rounded-lg">
              <div className="flex gap-md">
                <button className="btn btn-primary btn-square">
                  <Search /> Search
                </button>
                <button className="btn btn-outline btn-square">
                  <Bookmark /> Bookmark
                </button>
                <button className="btn btn-white btn-square">
                  <Share /> Share
                </button>
              </div>
            </div>
          </div>

          {/* Icon Only Buttons */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Icon Only Buttons</h3>
            <div className="p-lg bg-gray-50 rounded-lg">
              <div className="flex gap-md">
                <button className="btn-icon btn-icon-md">
                  <Search />
                </button>
                <button className="btn-icon btn-icon-md btn-icon-primary">
                  <Bookmark />
                </button>
                <button className="btn-icon btn-icon-md btn-icon-outline">
                  <Share />
                </button>
              </div>
            </div>
          </div>

          {/* Icon Button Sizes */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Icon Button Sizes</h3>
            <div className="p-lg bg-gray-50 rounded-lg">
              <div className="flex gap-md items-center">
                <button className="btn-icon btn-icon-sm btn-icon-primary">
                  <Search />
                </button>
                <button className="btn-icon btn-icon-md btn-icon-primary">
                  <Bookmark />
                </button>
                <button className="btn-icon btn-icon-lg btn-icon-primary">
                  <Share />
                </button>
              </div>
            </div>
          </div>

          {/* Disabled Buttons */}
          <div>
            <h3 className="text-lg font-semibold mb-md">Disabled Buttons</h3>
            <div className="p-lg bg-gray-50 rounded-lg">
              <div className="flex gap-md">
                <button className="btn btn-primary btn-square" disabled>
                  Disabled
                </button>
                <button className="btn btn-outline btn-square" disabled>
                  Disabled
                </button>
                <button className="btn-icon btn-icon-md" disabled>
                  <Search />
                </button>
              </div>
            </div>
          </div>

          {/* Gray Buttons */}
          <div className="mb-xl">
            <h3 className="text-lg font-semibold mb-md">Gray Buttons</h3>
            <div className="p-lg bg-gray-50 rounded-lg">
              <div className="flex gap-md">
                <button className="btn btn-gray btn-square">Gray</button>
                <button className="btn btn-gray-outline btn-square">Gray Outline</button>
                <button className="btn-icon btn-icon-gray">
                  <Search />
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Forms */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Forms</h2>
          <div className="flex flex-col gap-lg">
            <div>
              <label className="form-label mb-sm block">Input Label</label>
              <input type="text" className="form-input" placeholder="Regular input" />
            </div>
            <div>
              <label className="form-label mb-sm block">Disabled Input</label>
              <input type="text" className="form-input" disabled placeholder="Disabled input" />
            </div>
          </div>
        </section>

        {/* Badges */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Badges</h2>
          <div className="flex gap-md">
            <span className="badge badge-primary">Primary Badge</span>
            <span className="badge badge-outline">Outline Badge</span>
          </div>
        </section>

        {/* Cards */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Cards</h2>
          <div className="flex gap-md">
            <div className="card shadow-sm">
              <h3 className="text-lg font-semibold mb-sm">Light Shadow</h3>
              <p className="text-gray-600">Card with light shadow</p>
            </div>
            <div className="card shadow">
              <h3 className="text-lg font-semibold mb-sm">Regular Shadow</h3>
              <p className="text-gray-600">Card with regular shadow</p>
            </div>
            <div className="card shadow-lg">
              <h3 className="text-lg font-semibold mb-sm">Large Shadow</h3>
              <p className="text-gray-600">Card with large shadow</p>
            </div>
          </div>
        </section>

        {/* Icon Buttons */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Icon Buttons</h2>
          <div className="flex flex-col gap-lg">
            <h3 className="text-lg font-semibold mb-sm">Regular Icon Buttons</h3>
            <div className="flex gap-md mb-md">
              <button className="btn-icon">
                <Search />
              </button>
              <button className="btn-icon">
                <Bookmark />
              </button>
              <button className="btn-icon">
                <Share />
              </button>
              <button className="btn-icon">
                <Add />
              </button>
            </div>

            <h3 className="text-lg font-semibold mb-sm">Transparent Icon Buttons</h3>
            <div className="flex gap-md mb-md">
              <button className="btn-icon btn-icon-transparent btn-icon-md">
                <Search />
              </button>
            </div>

            <h3 className="text-lg font-semibold mb-sm">Primary Icon Buttons</h3>
            <div className="flex gap-md mb-md">
              <button className="btn-icon btn-icon-primary">
                <Settings />
              </button>
              <button className="btn-icon btn-icon-primary">
                <Edit />
              </button>
              <button className="btn-icon btn-icon-primary">
                <MoreVert />
              </button>
            </div>

            <h3 className="text-lg font-semibold mb-sm">Icon Buttons with Text</h3>
            <div className="flex gap-md mb-md">
              <button className="btn btn-primary btn-square">
                <Search className="mr-xs" /> Search
              </button>
              <button className="btn btn-outline btn-square">
                <Bookmark className="mr-xs" /> Bookmark
              </button>
              <button className="btn btn-white btn-square">
                <Share className="mr-xs" /> Share
              </button>
            </div>

            <h3 className="text-lg font-semibold mb-sm">Small Icon Buttons</h3>
            <div className="flex gap-md">
              <button className="btn-icon btn-icon-sm">
                <Search />
              </button>
              <button className="btn-icon btn-icon-sm btn-icon-primary">
                <Bookmark />
              </button>
              <button className="btn-icon btn-icon-sm">
                <Delete />
              </button>
            </div>
          </div>
        </section>

        {/* Layout Patterns */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Layout Patterns</h2>
          
          <h3 className="text-lg font-semibold mb-sm">Split Layout</h3>
          <div className="split-layout mb-lg">
            <div className="card">Left Content</div>
            <div className="card">Right Content</div>
          </div>

          <h3 className="text-lg font-semibold mb-sm">Content Container</h3>
          <div className="content-container card mb-lg">
            <p>Standard width container for main content</p>
          </div>

          <h3 className="text-lg font-semibold mb-sm">Centered Content</h3>
          <div className="centered-content card">
            <p>Centered content with max width</p>
          </div>
        </section>

        {/* Post Cards */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Post Cards</h2>
          
          <div className="post-card mb-md">
            <div className="post-header">
              <h3 className="post-title">Post Title</h3>
              <div className="post-actions">
                <button className="btn-icon btn-icon-sm">
                  <Bookmark />
                </button>
                <button className="btn-icon btn-icon-sm">
                  <Share />
                </button>
                <button className="btn-icon btn-icon-sm">
                  <MoreVert />
                </button>
              </div>
            </div>
            <div className="post-content">
              <p className="text-gray-700">Post content goes here...</p>
            </div>
            <div className="post-footer">
              <span className="post-meta">Posted 2 days ago</span>
              <div className="post-tags">
                <span className="tag">Tag 1</span>
                <span className="tag">Tag 2</span>
              </div>
            </div>
          </div>
        </section>

        {/* Modals */}
        <section className="bg-white rounded-lg p-xl mb-xl shadow-sm">
          <h2 className="text-2xl font-semibold mb-lg border-b pb-md">Modal Patterns</h2>
          
          <div className="card">
            <h3 className="text-lg font-semibold mb-sm">Standard Modal</h3>
            <div className="modal-example">
              <div className="modal-header">
                <h4 className="modal-title">Modal Title</h4>
                <button className="btn-icon btn-icon-sm">
                  <Close />
                </button>
              </div>
              <div className="modal-content">
                <p>Modal content goes here...</p>
              </div>
              <div className="modal-footer">
                <button className="btn btn-outline btn-square">Cancel</button>
                <button className="btn btn-primary btn-square">Confirm</button>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>
  );
};

export default UIKit;