import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import SearchPreviewNew from './SearchPreviewNew';
import { useAuth } from './AuthContext';
import SearchService from '../services/SearchService';

const SearchBar = ({ setData, startDate, endDate, setLoading, setNoResults, onSearch, setAnswer }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const initialQuery = searchParams.get('q') || '';

    const [inputValue, setInputValue] = useState(initialQuery);
    const searchBoxRef = useRef(null);
    const searchInputRef = useRef(null);
    const { userIsPremium, userId, flaskServerAddress, serverAddress } = useAuth();
    const searchService = new SearchService(flaskServerAddress, serverAddress);

    const [isInputFocused, setIsInputFocused] = useState(false);

    const fetchData = async (query) => {
        try {
            if (!query) return;
            
            setLoading(true);
            const result = await searchService.search(query, {
                startDate,
                endDate,
                userId
            });

            if (result) {
                if (result.type === 'question') {
                    setAnswer(result.answer);
                    setData(result.posts);
                } else {
                    setData(result.posts);
                }
                setNoResults(result.noResults);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
    };
    
    const handleInputBlur = () => {
        setTimeout(() => {
            setIsInputFocused(false);
        }, 200);
    };

    async function logSearch(query) {
        const response = await fetch(serverAddress + 'log-search/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: query || '',
                user_id: userId || null,
            }),
        });
        if (response.status !== 200) {
            console.error('Error logging search:', response);
        }
    }
    
    const handleSearch = () => {
        onSearch(inputValue);
        logSearch(inputValue);
        setIsInputFocused(false);
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleCompanyClick = (company) => {
        navigate(`/company/${company.id}`);
        setIsInputFocused(false);
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
    };

    const handleScroll = () => {
        setIsInputFocused(false);
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        console.log('SearchBar useEffect - startDate:', startDate, 'endDate:', endDate);
        const searchParams = new URLSearchParams(location.search);
        const query = searchParams.get('q') || '';
        if (query) {
            fetchData(query);
        }
    }, [startDate, endDate, location.search]);

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    return (
        <div className='search-box-exterior'>
            <div className='search-box' ref={searchBoxRef}>
                <input 
                    className='search-bar-input' 
                    value={inputValue} 
                    onChange={handleInputChange} 
                    placeholder="Search for a company, topic, or theme!" 
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                    ref={searchInputRef}
                />
                <button className='search-bar-button' onClick={handleSearch}>
                    <SearchIcon />
                </button>
            </div>
            {(
                <SearchPreviewNew 
                    query={inputValue} 
                    parentRef={searchInputRef}
                    anchorRef={searchBoxRef}
                    onlyShowCompanySearch={false}
                    handleClick={handleCompanyClick}
                    handleSearch={() => handleSearch()}
                    onClear={() => setInputValue('')}
                />
            )}
        </div>
    );
}

export default SearchBar;
