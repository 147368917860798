import { useState, useEffect, useCallback } from "react";
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { startOfMonth, format, parse } from 'date-fns';
import SettingsIcon from '@mui/icons-material/Settings';
import { subYears } from 'date-fns';
import DownloadIcon from '@mui/icons-material/Download';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TimeRangeSelector from './TimeRangeSelector';
import '../styles/NPSPlot.css';
import FilteredPostsDisplay from './FilteredPostsDisplay';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import ProductSelector from './ProductSelector';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CHART_COLORS = {
    main: 'rgb(47, 71, 184)',  // dark blue for main product
    competitors: [
        'rgb(75, 192, 192)',     // cyan blue
        'rgb(138, 43, 226)',    // Purple
        'rgb(134, 0, 46)',     // dark red
        'rgb(255, 99, 71)',     // Tomato red
        'rgb(50, 205, 50)',     // Lime green
        'rgb(255, 140, 0)',     // Dark orange
    ]
};

const NPSPlot = ({posts, stat_name, title, topics}) => {
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [postsReferenced, setPostsReferenced] = useState([]);
    const [showSettings, setShowSettings] = useState(false);

    const [baselineNPS, setBaselineNPS] = useState(44); // Median NPS for all companies
    const [padding, setPadding] = useState(50); // Number of simulated reviews
    
    const [averages, setAverages] = useState({ 
        year: { nps: null, raw: null, noPaddingNps: null, count: null },
        fiveYears: { nps: null, raw: null, noPaddingNps: null, count: null },
        allTime: { nps: null, raw: null, noPaddingNps: null, count: null }
    });
    
    const [isCumulative, setIsCumulative] = useState(false);
    const [cumulativeData, setCumulativeData] = useState([]);
    const [timeRange, setTimeRange] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const promoterThreshold = 7;
    const detractorThreshold = 5;
    const [selectedCompetitors, setSelectedCompetitors] = useState(new Set());
    const [competitorData, setCompetitorData] = useState({});
    const [showCompetitors, setShowCompetitors] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState("");

    const scrollToPosts = () => {
        const postsSection = document.getElementById('nps-posts');
        if (postsSection) {
            const padding = 80; // Adjust this value as needed
            const targetPosition = postsSection.getBoundingClientRect().top + window.pageYOffset - padding;
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });
        }
    };

    const determineInitialTimeRange = (postsData) => {
        if (!postsData || postsData.length === 0) return '3y';

        // Get the main company's data (first dataset)
        const mainCompanyData = Object.values(competitorData)[0] || [];
        if (mainCompanyData.length === 0) return '3y';

        const now = new Date();
        const earliestPost = Math.min(...mainCompanyData.map(post => post.time));
        const earliestDate = new Date(earliestPost * 1000);
        const yearsDiff = (now - earliestDate) / (1000 * 60 * 60 * 24 * 365);

        // Select the appropriate time range that would include all data
        if (yearsDiff <= 1) return '1y';
        if (yearsDiff <= 3) return '3y';
        if (yearsDiff <= 5) return '5y';
        if (yearsDiff <= 10) return '10y';
        return 'all';
    };

    const filterPostsByProduct = (posts) => {
        if (!selectedProduct || !topics) return posts;
        
        const selectedTopic = topics.find(t => t.topic === selectedProduct);
        if (!selectedTopic) return posts;
        
        // Return only posts that belong to the selected topic
        return selectedTopic.posts
            .map(post => {
                if (!post?.post_assessments) return null;
                const assessment = post.post_assessments.find(assessment => assessment?.type === stat_name);
                if (assessment) {
                    return {
                        ...assessment,
                        time: post.time,
                        id: post.id
                    };
                }
                return null;
            })
            .filter(item => item !== null && item?.rating !== null);
    };

    const fetchData = async () => {
        const data = (posts || [])
            .map(post => {
                if (!post?.post_assessments) return null;
                const assessment = post.post_assessments.find(assessment => assessment?.type === stat_name);
                if (assessment) {
                    return {
                        ...assessment,
                        time: post.time,
                        id: post.id
                    };
                }
                return null;
            })
            .filter(item => item !== null);

        // Filter posts by selected product
        const filteredData = selectedProduct ? filterPostsByProduct(data) : data.filter(item => item?.rating !== null);
        
        setData(filteredData);
        
        // Set initial time range based on data
        if (!timeRange) {
            setTimeRange(determineInitialTimeRange(filteredData));
        }
    };

    const calculateTrailingAverages = (postsData) => {
        if (!postsData || postsData.length === 0) return [];

        // Sort data by date
        const sortedData = [...postsData].sort((a, b) => a.time - b.time);
        
        // Get the range of months
        const startDate = startOfMonth(new Date(sortedData[0].time * 1000));
        const endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
        
        // Generate all months between start and end
        const allMonths = [];
        let currentMonth = startDate;
        while (currentMonth <= endDate) {
            allMonths.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0));
            currentMonth.setMonth(currentMonth.getMonth() + 1);
        }
        
        // Calculate trailing 6-month averages for each month
        let lastValidAverage = null;
        let lastValidPosts = [];

        return allMonths.map(month => {
            const sixMonthsAgo = new Date(month);
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
            
            // Get posts from this 6-month period
            const postsInPeriod = sortedData.filter(d => {
                const postDate = new Date(d.time * 1000);
                return postDate >= sixMonthsAgo && postDate <= month;
            });

            // Get posts specifically from this month for reference
            const thisMonthPosts = sortedData.filter(d => {
                const postDate = new Date(d.time * 1000);
                return postDate.getMonth() === month.getMonth() && 
                       postDate.getFullYear() === month.getFullYear();
            });

            let average;
            // Only calculate a new average if we have new posts in this period
            if (thisMonthPosts.length > 0) {
                const n_simulated_reviews = padding;
                const simulated_score = baselineNPS;
                
                const sum = postsInPeriod.reduce((acc, curr) => {
                    if (curr.rating > promoterThreshold) return acc + 100;
                    if (curr.rating <= detractorThreshold) return acc - 100;
                    return acc;  // Passives (6-7) count as 0
                }, 0);
                const totalSum = sum + (n_simulated_reviews * simulated_score);
                const totalCount = postsInPeriod.length + n_simulated_reviews;
                
                average = Number((totalSum / totalCount).toFixed(1));
                lastValidAverage = average;
                lastValidPosts = postsInPeriod;
            } else {
                // If no new posts this month, carry forward the last known average
                average = lastValidAverage;
            }
            
            return { 
                x: format(month, 'yyyy-MM'), 
                y: average,
                originalDataPoints: thisMonthPosts, // Only include posts from this specific month
                rawAverage: thisMonthPosts.length > 0 ? 
                    thisMonthPosts.reduce((sum, curr) => sum + curr.rating, 0) / thisMonthPosts.length : 
                    null,
                count: thisMonthPosts.length
            };
        }).filter(item => item.y !== null); // Remove any periods before we have data
    };

    const calculateCumulativeDataForPosts = (postsData) => {
        if (!postsData || postsData.length === 0) return [];

        const sortedData = [...postsData].sort((a, b) => a.time - b.time);
        let cumulativeSum = baselineNPS * padding;
        let cumulativeCount = padding;
        
        return sortedData.map((item, index) => {
            const itemDate = new Date(item.time * 1000);
            let npsScore = 0;
            if (item.rating > promoterThreshold) npsScore = 100;
            else if (item.rating <= detractorThreshold) npsScore = -100;
            else npsScore = item.rating;

            cumulativeSum += npsScore;
            cumulativeCount++;

            const average = Number((cumulativeSum / cumulativeCount).toFixed(1));

            return {
                x: format(itemDate, 'yyyy-MM'),
                y: average,
                originalDataPoints: [item],
                rawAverage: item.rating,
                count: index + 1
            };
        });
    };

    const handleDownloadCSV = () => {
        if (!chartData || !postsReferenced) return;
    
        // Original CSV content
        const aggregatedCsvContent = [
            'period,NPS,Count,Average Review Score',
            ...chartData.datasets[0].data
                .filter(item => item.y !== null)
                .map(item => {
                    const date = parse(item.x, 'yyyy-MM', new Date());
                    const formattedDate = format(date, 'M/d/yyyy');
                    const averageReviewScore = item.rawAverage !== null ? item.rawAverage.toFixed(1) : '';
                    return `${formattedDate},${item.y},${item.count},${averageReviewScore}`;
                })
        ].join('\n');

        // New CSV content for raw post data
        const rawPostsCsvContent = [
            'text,score,rationale,date,url',
            ...data.map(item => {
                const date = new Date(item.time * 1000);
                const formattedDate = format(date, 'M/d/yyyy');
                const combinedText = `${item.title || ''} ${item.text || ''}`.replace(/"/g, '""').replace(/<[^>]*>/g, '');
                const url = `scuttlebuttresearch.com/post/${item.id}`;
                return `"${combinedText}",${item.rating},"${item.explanation.replace(/"/g, '""')}",${formattedDate},"${url}"`;
            })
        ].join('\n');

    
        // Function to create and trigger download
        const downloadCsv = (content, filename) => {
            const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        };
    
        // Download both CSVs
        downloadCsv(aggregatedCsvContent, `nps_scores_${stat_name}.csv`);
        downloadCsv(rawPostsCsvContent, `raw_posts_${stat_name}.csv`);
    };

    useEffect(() => {
        if (Array.isArray(posts) && posts.length > 0) {
            fetchData();
        }
    }, [posts, selectedProduct]);

    const handlePointClick = (event, elements) => {
        if (elements.length > 0) {
            const { datasetIndex, index } = elements[0];
            const pointData = chartData.datasets[datasetIndex].data[index];
            const { x: month, y: value, originalDataPoints } = pointData;
            const productName = chartData.datasets[datasetIndex].label;
            
            // Get the date range for the 6-month period
            const endDate = parse(month, 'yyyy-MM', new Date());
            const startDate = new Date(endDate);
            startDate.setMonth(startDate.getMonth() - 6);

            // Get all posts from the selected dataset that fall within this period
            const datasetPosts = competitorData[productName] || [];
            const postsInPeriod = datasetPosts.filter(post => {
                const postDate = new Date(post.time * 1000);
                return postDate >= startDate && postDate <= endDate;
            });

            // Find the full post data from the topics
            const postsReferenced = postsInPeriod.map(assessmentPost => {
                let fullPost;
                if (productName === selectedProduct) {
                    // For selected product, find in its posts
                    const selectedTopic = topics.find(t => t.topic === selectedProduct);
                    fullPost = selectedTopic?.posts?.find(p => p.id === assessmentPost.id);
                } else {
                    // For competitors, find in competitor posts
                    const selectedTopic = topics.find(t => t.topic === selectedProduct);
                    const competitor = selectedTopic?.competitors?.find(c => c.topic === productName);
                    fullPost = competitor?.posts?.find(p => p.id === assessmentPost.id);
                }

                if (!fullPost) return null;

                return {
                    ...fullPost, // This includes title, text, url, etc.
                    rating: assessmentPost.rating,
                    confidence: assessmentPost.confidence,
                    explanation: assessmentPost.explanation,
                    ratingName: stat_name,
                    productName: productName,
                    ratingType: stat_name
                };
            }).filter(Boolean);

            setPostsReferenced(postsReferenced);
            // Use setTimeout to ensure the DOM has updated before scrolling
            setTimeout(scrollToPosts, 0);
        }
    };

    const handleClosePostsReferenced = () => {
        setPostsReferenced([]);
    };

    useEffect(() => {
        if (data.length > 0) {
            const cumulativeData = calculateCumulativeDataForPosts(data);
            setCumulativeData(cumulativeData);
            // Sort data by date
            const sortedData = data.sort((a, b) => a.time - b.time);
            
            // Get the range of months
            const startDate = startOfMonth(new Date(sortedData[0].time * 1000));
            const endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(0);
            
            // Generate all months between start and end
            const allMonths = [];
            let currentMonth = startDate;
            while (currentMonth <= endDate) {
                allMonths.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0));
                currentMonth.setMonth(currentMonth.getMonth() + 1);
            }
            
            // Calculate trailing 6-month averages for each month
            let previousAverage = null;
            const trailingAverages = allMonths.map(month => {
                const sixMonthsAgo = new Date(month);
                sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
                
                const last6Months = sortedData.filter(d => 
                    new Date(d.time * 1000) >= sixMonthsAgo && 
                    new Date(d.time * 1000) <= month
                );
                
                let average;
                if (last6Months.length > 0) {
                    const n_simulated_reviews = padding; // Use padding for simulated reviews
                    const simulated_score = baselineNPS; // Use baselineNPS for simulated score
                    
                    const sum = last6Months.reduce((acc, curr) => {
                        if (curr.rating > promoterThreshold) return acc + 100;
                        if (curr.rating <= detractorThreshold) return acc - 100;
                        return acc;  // Passives (6-7) count as 0
                    }, 0);
                    const totalSum = sum + (n_simulated_reviews * simulated_score);
                    const totalCount = last6Months.length + n_simulated_reviews;
                    
                    average = Number((totalSum / totalCount).toFixed(1));
                    previousAverage = average;
                } else {
                    average = previousAverage ? Number(previousAverage.toFixed(1)) : null;
                }
                
                return { 
                    x: format(month, 'yyyy-MM'), 
                    y: average,
                    originalDataPoints: last6Months,
                    rawAverage: last6Months.length > 0 ? last6Months.reduce((sum, curr) => sum + curr.rating, 0) / last6Months.length : null,
                    count: last6Months.length
                };
            });
    
            const filteredData = filterDataByTimeRange(
                isCumulative ? cumulativeData : trailingAverages
            );

            setChartData({
                labels: filteredData.map(item => item.x),
                datasets: [{
                    label: isCumulative ? `Cumulative ${stat_name} Rating` : `Trailing 6-Month Average ${stat_name} Rating`,
                    data: filteredData,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }]
            });

            const now = new Date();
            const oneYearAgo = subYears(now, 1);
            const fiveYearsAgo = subYears(now, 5);

            const calculateAverages = (startDate) => {
                const filteredData = sortedData.filter(d => new Date(d.time * 1000) >= startDate);
                const monthsSince = Math.max(1, Math.ceil((now - startDate) / (1000 * 60 * 60 * 24 * 30))) / 6;
                // Need to divide by 6 because the padding is used on a trailing 6mo period
                const paddingValue = baselineNPS * monthsSince * padding; 

                let npsSum = 0;
                let rawSum = 0;
                filteredData.forEach(curr => {
                    if (curr.rating > 8) npsSum += 100;
                    else if (curr.rating <= 6) npsSum -= 100;
                    else npsSum += curr.rating;
                    rawSum += curr.rating;
                });

                const totalNpsSum = npsSum + paddingValue;
                const totalCount = filteredData.length + (monthsSince * padding);

                return {
                    nps: Number((totalNpsSum / totalCount).toFixed(0)),
                    noPaddingNps: Number((npsSum / filteredData.length).toFixed(0)),
                    raw: Number((rawSum / filteredData.length).toFixed(1)),
                    count: filteredData.length
                };
            };

            setAverages({
                year: calculateAverages(oneYearAgo),
                fiveYears: calculateAverages(fiveYearsAgo),
                allTime: calculateAverages(new Date(0)) // From the beginning of time
            });
        }
    }, [data, stat_name, baselineNPS, padding, isCumulative, timeRange]);

    const handleSettingsClick = () => {
        setShowSettings(!showSettings);
    };

    const handleToggleChange = (event) => {
        setIsCumulative(event.target.checked);
    };

    const handleTimeRangeChange = (event, newRange) => {
        if (newRange !== null) {
            setTimeRange(newRange);
        }
    };

    const filterDataByTimeRange = (data) => {
        if (timeRange === 'all') return data;
        
        const now = new Date();
        const yearsToShow = parseInt(timeRange);
        const cutoffDate = new Date(now.setFullYear(now.getFullYear() - yearsToShow, 0, 1));
        
        return data.filter(item => {
            const itemDate = parse(item.x, 'yyyy-MM', new Date());
            return itemDate >= cutoffDate;
        });
    };

    const getNPSColorClass = (nps) => {
        if (nps >= 30) return 'positive';
        if (nps <= 0) return 'negative';
        return 'neutral';
    };

    // Modify the useEffect that updates competitorData
    useEffect(() => {
        if (!topics) return;

        // If there's only one product, automatically select it
        if (topics.length === 1 && !selectedProduct) {
            setSelectedProduct(topics[0].topic);
        }

        const newCompetitorData = {};
        
        if (selectedProduct) {
            // If a product is selected, show that product and its competitors
            const selectedTopic = topics.find(topic => topic.topic === selectedProduct);
            if (selectedTopic) {
                // Add the main product's data
                const productPosts = (selectedTopic.posts || [])
                    .map(post => {
                        if (!post?.post_assessments) return null;
                        const assessment = post.post_assessments.find(a => a?.type === stat_name);
                        if (assessment) {
                            return {
                                ...assessment,
                                time: post.time,
                                id: post.id
                            };
                        }
                        return null;
                    })
                    .filter(item => item !== null && item?.rating !== null);

                if (productPosts.length > 0) {
                    newCompetitorData[selectedProduct] = productPosts;
                }

                // Add competitor data for this product
                if (selectedTopic.competitors) {
                    selectedTopic.competitors.forEach(competitor => {
                        if (!competitor?.posts) return;

                        const competitorPosts = competitor.posts
                            .map(post => {
                                if (!post?.post_assessments) return null;
                                const assessment = post.post_assessments.find(a => a?.type === stat_name);
                                if (assessment) {
                                    return {
                                        ...assessment,
                                        time: post.time,
                                        id: post.id
                                    };
                                }
                                return null;
                            })
                            .filter(item => item !== null && item?.rating !== null);

                        if (competitorPosts.length > 0) {
                            newCompetitorData[competitor.topic] = competitorPosts;
                        }
                    });
                }
            }
        } else {
            // If no product is selected, show all products (but not competitors)
            topics.forEach(topic => {
                if (topic?.posts?.length > 0) {
                    const productPosts = topic.posts
                        .map(post => {
                            if (!post?.post_assessments) return null;
                            const assessment = post.post_assessments.find(a => a?.type === stat_name);
                            if (assessment) {
                                return {
                                    ...assessment,
                                    time: post.time,
                                    id: post.id
                                };
                            }
                            return null;
                        })
                        .filter(item => item !== null && item?.rating !== null);

                    if (productPosts.length > 0) {
                        newCompetitorData[topic.topic] = productPosts;
                    }
                }
            });
        }

        setCompetitorData(newCompetitorData);
    }, [topics, selectedProduct, stat_name]);

    // Add a function to check if competitors exist for the selected product
    const hasCompetitors = () => {
        if (!selectedProduct || !topics) return false;
        
        const selectedTopic = topics.find(topic => topic.topic === selectedProduct);
        return selectedTopic?.competitors?.some(competitor => 
            competitor.posts?.some(post => 
                post?.post_assessments?.some(assessment => 
                    assessment?.type === stat_name && assessment?.rating !== null
                )
            )
        ) || false;
    };

    // Modify the JSX where we render the competitor toggle
    const renderCompetitorToggle = () => {
        if (selectedProduct && hasCompetitors()) {
            return (
        <FormControlLabel
            control={
                <Switch 
                    checked={showCompetitors} 
                    onChange={(e) => setShowCompetitors(e.target.checked)}
                />
            }
            label="Show Competitors"
        />
            );
        }
        return null;
    };

    // Modify the existing useEffect that sets chart data
    useEffect(() => {
        if (data.length > 0) {
            const datasets = [];
            let allLabels = new Set();
            
            // Add main product datasets first
            Object.keys(competitorData).forEach((productName, index) => {
                const productPosts = competitorData[productName];
                if (productPosts) {
                    const trailingAverages = calculateTrailingAverages(productPosts);
                    const filteredProductData = filterDataByTimeRange(
                        isCumulative ? 
                            calculateCumulativeDataForPosts(productPosts) : 
                            trailingAverages
                    );

                    // Collect all unique labels (months)
                    filteredProductData.forEach(item => allLabels.add(item.x));

                    // Only add competitors if showCompetitors is true
                    if (index === 0 || showCompetitors) {
                        datasets.push({
                            label: productName,
                            data: filteredProductData,
                            borderColor: index === 0 ? CHART_COLORS.main : CHART_COLORS.competitors[index - 1],
                            tension: 0.1,
                            borderWidth: 2,
                        });
                    }
                }
            });

            // Sort labels chronologically
            const sortedLabels = Array.from(allLabels).sort();

            setChartData({
                labels: sortedLabels,
                datasets
            });
        }
    }, [data, stat_name, baselineNPS, padding, isCumulative, timeRange, competitorData, showCompetitors]);

    const handleMetricCardClick = (period) => {
        const now = new Date();
        let startDate;
        
        switch(period) {
            case 'year':
                startDate = subYears(now, 1);
                break;
            case 'fiveYears':
                startDate = subYears(now, 5);
                break;
            case 'allTime':
                startDate = new Date(0); // Beginning of time
                break;
            default:
                return;
        }

        // Filter posts for the selected time period
        const filteredPosts = (posts || []).map(post => {
            if (!post?.post_assessments) return null;
            const assessment = post.post_assessments.find(assessment => assessment?.type === stat_name);
            if (assessment && new Date(post.time * 1000) >= startDate) {
                return {
                    ...post,
                    rating: assessment.rating,
                    confidence: assessment.confidence,
                    explanation: assessment.explanation,
                    ratingName: stat_name
                };
            }
            return null;
        }).filter(Boolean);

        setPostsReferenced(filteredPosts);
        setTimeout(scrollToPosts, 0);
    };

    return (
        <div>
            {chartData && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
                        <div className="fundamentals-controls">
                            {topics && topics.length > 0 && (
                                <ProductSelector
                                    products={topics}
                                    selectedProduct={selectedProduct}
                                    onProductChange={setSelectedProduct}
                                    className="product-selector"
                                    hideAllOption={topics.length === 1}
                                />
                            )}
                            {/* <FormControlLabel
                                control={<Switch checked={isCumulative} onChange={handleToggleChange} />}
                                label="Cumulative"
                            /> */}
                            {renderCompetitorToggle()}
                            {/* <button onClick={handleDownloadCSV} style={{ background: 'none', border: 'none', cursor: 'pointer', marginRight: '10px' }}>
                                <DownloadIcon />
                            </button>
                            <button onClick={handleSettingsClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                <SettingsIcon />
                            </button> */}
                            <TimeRangeSelector
                                timeRange={timeRange}
                                onTimeRangeChange={setTimeRange}
                            />
                        </div>
                    </div>
                    {showSettings && (
                        <div style={{ marginBottom: '20px' }}>
                            <label>
                                Baseline NPS:
                                <input 
                                    type="number" 
                                    value={baselineNPS} 
                                    onChange={(e) => setBaselineNPS(Number(e.target.value))}
                                    style={{ marginLeft: '10px', marginRight: '20px' }}
                                />
                            </label>
                            <label>
                                Padding:
                                <input 
                                    type="number" 
                                    value={padding} 
                                    onChange={(e) => setPadding(Number(e.target.value))}
                                    style={{ marginLeft: '10px' }}
                                />
                            </label>
                        </div>
                    )}
                    <Line
                        data={chartData}
                        options={{
                            responsive: true,
                            scales: {
                                y: {
                                    min: -100,
                                    max: 100,
                                    title: {
                                        display: true,
                                        text: 'Net Promoter Score (Estimated)',
                                        font: {
                                            size: 14,
                                            weight: 'bold'
                                        }
                                    }
                                },
                                x: {
                                    title: {
                                        display: true,
                                        text: 'Time Period',
                                        font: {
                                            size: 14,
                                            weight: 'bold'
                                        }
                                    }
                                }
                            },
                            plugins: {
                                title: {
                                    display: false
                                },
                                legend: {
                                    display: showCompetitors && Object.keys(competitorData).length > 1,
                                    position: 'top',
                                    align: 'end',
                                    labels: {
                                        usePointStyle: true,
                                        pointStyle: 'circle',
                                        padding: 15,
                                        boxWidth: 10,
                                        boxHeight: 10,
                                    }
                                }
                            },
                            onClick: handlePointClick,
                        }}
                        plugins={[{
                            id: 'backgroundColorPlugin',
                            beforeDraw: (chart) => {
                                const ctx = chart.canvas.getContext('2d');
                                const chartArea = chart.chartArea;
                                const yAxis = chart.scales.y;
                                const min = -100;
                                const low = 0;
                                const mid = 30;
                                const high = 100;

                                // Light red background for y < 6
                                ctx.fillStyle = 'rgba(255, 200, 200, 0.5)';
                                ctx.fillRect(chartArea.left, yAxis.getPixelForValue(min), chartArea.right - chartArea.left, yAxis.getPixelForValue(low) - yAxis.getPixelForValue(min));

                                // Yellow background for 6 <= y < 8
                                ctx.fillStyle = 'rgba(255, 255, 200, 0.5)';
                                ctx.fillRect(chartArea.left, yAxis.getPixelForValue(mid), chartArea.right - chartArea.left, yAxis.getPixelForValue(low) - yAxis.getPixelForValue(mid));

                                // Green background for y >= 8
                                ctx.fillStyle = 'rgba(200, 255, 200, 0.5)';
                                ctx.fillRect(chartArea.left, yAxis.getPixelForValue(high), chartArea.right - chartArea.left, yAxis.getPixelForValue(mid) - yAxis.getPixelForValue(high));
                            }
                        }]}
                    />
                    <br />
                    <h4 className="nps-header">Average Ratings</h4>
                    <div className="nps-metrics-container">
                        <div className="nps-metric-card" onClick={() => handleMetricCardClick('year')} style={{ cursor: 'pointer' }}>
                            <div className="metric-period">Past Year</div>
                            <div className="metric-row">
                                <span className="metric-label">NPS (Adjusted)</span>
                                <span className={`metric-value ${getNPSColorClass(averages.year.nps)}`}>
                                    {averages.year.nps}
                                </span>
                            </div>
                            <div className="metric-row">
                                <span className="metric-label">NPS (Raw)</span>
                                <span className={`metric-value ${getNPSColorClass(averages.year.noPaddingNps)}`}>
                                    {averages.year.noPaddingNps}
                                </span>
                            </div>
                            <div className="metric-row">
                                <span className="metric-label">Average Rating</span>
                                <span className="metric-value">
                                    {averages.year.raw}/10
                                </span>
                            </div>
                            <div className="sample-size">
                                Sample size: {averages.year.count}
                            </div>
                        </div>

                        <div className="nps-metric-card" onClick={() => handleMetricCardClick('fiveYears')} style={{ cursor: 'pointer' }}>
                            <div className="metric-period">Past 5 Years</div>
                            <div className="metric-row">
                                <span className="metric-label">NPS (Adjusted)</span>
                                <span className={`metric-value ${getNPSColorClass(averages.fiveYears.nps)}`}>
                                    {averages.fiveYears.nps}
                                </span>
                            </div>
                            <div className="metric-row">
                                <span className="metric-label">NPS (Raw)</span>
                                <span className={`metric-value ${getNPSColorClass(averages.fiveYears.noPaddingNps)}`}>
                                    {averages.fiveYears.noPaddingNps}
                                </span>
                            </div>
                            <div className="metric-row">
                                <span className="metric-label">Average Rating</span>
                                <span className="metric-value">
                                    {averages.fiveYears.raw}/10
                                </span>
                            </div>
                            <div className="sample-size">
                                Sample size: {averages.fiveYears.count}
                            </div>
                        </div>

                        <div className="nps-metric-card" onClick={() => handleMetricCardClick('allTime')} style={{ cursor: 'pointer' }}>
                            <div className="metric-period">All Time</div>
                            <div className="metric-row">
                                <span className="metric-label">NPS (Adjusted)</span>
                                <span className={`metric-value ${getNPSColorClass(averages.allTime.nps)}`}>
                                    {averages.allTime.nps}
                                </span>
                            </div>
                            <div className="metric-row">
                                <span className="metric-label">NPS (Raw)</span>
                                <span className={`metric-value ${getNPSColorClass(averages.allTime.noPaddingNps)}`}>
                                    {averages.allTime.noPaddingNps}
                                </span>
                            </div>
                            <div className="metric-row">
                                <span className="metric-label">Average Rating</span>
                                <span className="metric-value">
                                    {averages.allTime.raw}/10
                                </span>
                            </div>
                            <div className="sample-size">
                                Sample size: {averages.allTime.count}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {postsReferenced.length > 0 &&
                <FilteredPostsDisplay 
                    posts={postsReferenced}
                    onClose={handleClosePostsReferenced}
                    id="nps-posts"
                    title={"Referenced Posts"}
                    ratingDisplayName={stat_name}
                />
            }
        </div>
    )
}

export default NPSPlot;