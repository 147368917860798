import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import TimeRangeSelector from './TimeRangeSelector';
import { subYears } from 'date-fns';
import FilteredPostsDisplay from './FilteredPostsDisplay';
import ProductSelector from './ProductSelector';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CompetitiveComparisonPlot = ({ posts, product_name, topics }) => {
    const [comparisons, setComparisons] = React.useState({});
    const [selectedComparisons, setSelectedComparisons] = React.useState([]);
    const [postsReferenced, setPostsReferenced] = React.useState([]);
    const [selectedCompetitor, setSelectedCompetitor] = React.useState(null);
    const [timeRange, setTimeRange] = React.useState('3y');
    const [selectedProduct, setSelectedProduct] = React.useState("");

    const filterPostsByProduct = (posts) => {
        if (!selectedProduct || !topics) return posts;
        
        const selectedTopic = topics.find(t => t.topic === selectedProduct);
        if (!selectedTopic) return posts;
        
        return selectedTopic.posts;
    };

    React.useEffect(() => {
        const comparisonCounts = {};
        const now = new Date();
        
        const filteredByTime = (posts || []).filter(post => {
            if (timeRange === 'all') return true;
            const date = new Date(post?.time * 1000);
            const years = parseInt(timeRange);
            return date >= subYears(now, years);
        });

        const filteredPosts = filterPostsByProduct(filteredByTime);

        filteredPosts.forEach(item => {
            if (!item?.post_assessments) return;
            const assessment = item.post_assessments.find(a => a?.type === 'competitive_comparison');
            if (assessment?.details?.competitor) {
                const competitor = assessment.details.competitor;
                if (!comparisonCounts[competitor]) {
                    comparisonCounts[competitor] = { total: 0, positive: 0, negative: 0 };
                }
                comparisonCounts[competitor].total += 1;
                
                if (assessment.rating > 0) {
                    comparisonCounts[competitor].positive += 1;
                } else if (assessment.rating < 0) {
                    comparisonCounts[competitor].negative += 1;
                }
            }
        });

        const sortedComparisons = Object.entries(comparisonCounts)
            .sort((a, b) => b[1].total - a[1].total)
            .slice(0, 5);

        setComparisons(Object.fromEntries(sortedComparisons));
        setSelectedComparisons(Object.keys(Object.fromEntries(sortedComparisons)));
    }, [posts, timeRange, selectedProduct, topics]);

    const calculatePercentage = (competitor) => {
        const stats = comparisons[competitor];
        if (!stats || stats.total === 0) return 0;
        return Math.round((stats.positive / stats.total) * 100);
    };

    const prepareChartData = () => {
        const labels = selectedComparisons;
        const positiveData = labels.map(label => comparisons[label]?.positive || 0);
        const negativeData = labels.map(label => -(comparisons[label]?.negative || 0));
        const netData = positiveData.map((positive, index) => positive + negativeData[index]);
    
        return {
            labels,
            datasets: [
                {
                    label: 'Positive',
                    data: positiveData,
                    backgroundColor: 'rgba(144, 238, 144, 0.7)',
                    borderColor: 'rgba(144, 238, 144, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Negative',
                    data: negativeData,
                    backgroundColor: 'rgba(255, 99, 71, 0.7)',
                    borderColor: 'rgba(255, 99, 71, 1)',
                    borderWidth: 1,
                },
                {
                    type: 'line',
                    label: 'Net',
                    data: netData,
                    borderColor: 'rgba(0, 0, 0, 0.7)',
                    borderWidth: 2,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                    fill: false,
                }
            ]
        };
    };

    const handleChartClick = (event, elements) => {
        if (elements.length > 0) {
            const { index, datasetIndex } = elements[0];
            const competitor = selectedComparisons[index];
            setSelectedCompetitor(competitor);

            const isPositive = datasetIndex === 0;
            const isNegative = datasetIndex === 1;
            
            if (isPositive || isNegative) {
                const competitorPosts = (posts || []).filter(post => 
                    post?.post_assessments?.some(a => {
                        if (a?.type !== 'competitive_comparison' || 
                            a?.details?.competitor !== competitor) {
                            return false;
                        }
                        return isPositive ? a.rating > 0 : a.rating < 0;
                    })
                )
                .map(post => {
                    const assessment = post?.post_assessments?.find(a => 
                        a?.type === 'competitive_comparison' && 
                        a?.details?.competitor === competitor
                    );
                    return {
                        ...post,
                        rating: assessment?.rating,
                        confidence: assessment?.confidence,
                        explanation: assessment?.explanation,
                        ratingName: 'Competitive Comparison',
                        ratingType: 'competitive_comparison'
                    };
                });
                setPostsReferenced(competitorPosts);
            }
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px', marginBottom: '20px' }}>
                <div className="fundamentals-controls">
                    {topics && topics.length > 0 && (
                        <ProductSelector
                            products={topics}
                            selectedProduct={selectedProduct}
                            onProductChange={setSelectedProduct}
                            className="product-selector"
                        />
                    )}
                    <TimeRangeSelector
                        timeRange={timeRange}
                        onTimeRangeChange={setTimeRange}
                        periods={['1y', '3y', '5y', 'all']}
                    />
                </div>
            </div>
            <Bar
                data={prepareChartData()}
                options={{
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                            title: {
                                display: true,
                                text: 'Competitor Products'
                            }
                        },
                        y: {
                            stacked: true,
                            title: {
                                display: true,
                                text: 'Number of Mentions'
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const value = Math.abs(context.parsed.y);
                                    const competitor = context.label;
                                    const stats = comparisons[competitor];
                                    const currentProduct = selectedProduct || product_name;
                                    
                                    if (context.dataset.label === 'Net') {
                                        return `Total mentions: ${stats?.total || 0}`;
                                    }
                                    
                                    if (context.dataset.label === 'Positive') {
                                        const percentage = Math.round((stats?.positive / stats?.total) * 100) || 0;
                                        return `${percentage}% of users prefer ${currentProduct} (${value} mentions)`;
                                    }
                                    
                                    if (context.dataset.label === 'Negative') {
                                        const percentage = Math.round((stats?.negative / stats?.total) * 100) || 0;
                                        return `${percentage}% of users prefer ${competitor} (${value} mentions)`;
                                    }
                                }
                            }
                        }
                    },
                    onClick: handleChartClick,
                }}
            />
            {postsReferenced.length > 0 && (
                <FilteredPostsDisplay
                    posts={postsReferenced}
                    onClose={() => setPostsReferenced([])}
                    title={`Posts about ${selectedCompetitor}`}
                    subtitle={`${postsReferenced.length} posts`}
                    ratingColorScheme={{ high: 5, low: 0 }}
                />
            )}
        </div>
    );
};

export default CompetitiveComparisonPlot; 