import React, { useContext, useMemo, useEffect, useRef } from "react";
import { ModalContext } from '../../App';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

export const Header = (props) => {
    const modalContext = useContext(ModalContext);
    const navigate = useNavigate();
    const { userId } = useAuth();
    const videoRef = useRef(null);

    // useEffect(() => {
    //     if (videoRef.current) {
    //         videoRef.current.playbackRate = 0.75;
    //     }
    // }, []);

    // Create memoized styles based on screen size
    const titleStyle = useMemo(() => {
        const baseSize = props.titleSize || '60px';
        return {
            fontSize: baseSize,
            '@media (max-width: 768px)': {
                fontSize: props.mobileTitleSize || '40px'
            }
        };
    }, [props.titleSize, props.mobileTitleSize]);

    const handleButtonClick = (link) => {
        window.location.href = link;
    };

    return (
        <header id="header">
            <div className="intro sine-background">
                {/* <video 
                    ref={videoRef}
                    autoPlay 
                    loop 
                    muted 
                    playsInline
                >
                    <source src="/img/wave_animation.mp4" type="video/mp4" />
                </video> */}
                <div className="overlay sine-background">
                    <div className="container wide-width">
                        <div className="row">
                            <div className="intro-text">
                                <h1 className="responsive-title">
                                    {props.titleOverride ? props.titleOverride : props.data ? props.data.title : "Loading"}
                                </h1>
                                <p>{props.paragraphOverride ? props.paragraphOverride : props.data ? props.data.paragraph : "Loading"}</p>
                                <div className="intro-buttons">
                                    <button
                                        className="btn-square btn-primary btn-very-wide btn-lg btn-bold"
                                        onClick={() => handleButtonClick(props.ctaLink || '/book-demo')}
                                    >
                                        {props.ctaText || "Book Demo"}
                                    </button>
                                    <button
                                        className="btn-square btn-white-outline btn-very-wide btn-lg btn-bold"
                                        onClick={() => handleButtonClick('#about')}
                                    >
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};