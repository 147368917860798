import { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { startOfMonth, format, subYears } from 'date-fns';
import TimeRangeSelector from './TimeRangeSelector';
import FilteredPostsDisplay from './FilteredPostsDisplay';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LeaverJoinerPlot = ({posts, stat_name}) => {
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [postsReferenced, setPostsReferenced] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [minObservations, setMinObservations] = useState(20);
    const [timeRange, setTimeRange] = useState('3y');

    const [buckets, setBuckets] = useState([
        { name: 'Strong Joiners', min: 6, max: Infinity, color: 'rgba(0, 255, 0, 0.7)' },
        { name: 'Possible Joiners', min: 1, max: 6, color: 'rgba(144, 238, 144, 0.7)' },
        { name: 'Strong Leavers', min: -Infinity, max: -4, color: 'rgba(255, 0, 0, 0.7)' },
        { name: 'Possible Leavers', min: -4, max: -1, color: 'rgba(255, 99, 71, 0.7)' },
    ]);

    const determineInitialTimeRange = (postsData) => {
        if (!postsData || postsData.length === 0) return '3y';

        const now = new Date();
        const earliestPost = Math.min(...postsData.map(post => post.time));
        const earliestDate = new Date(earliestPost * 1000);
        const yearsDiff = (now - earliestDate) / (1000 * 60 * 60 * 24 * 365);

        if (yearsDiff <= 1) return '1y';
        if (yearsDiff <= 3) return '3y';
        if (yearsDiff <= 5) return '5y';
        if (yearsDiff <= 10) return '10y';
        return 'all';
    };

    const fetchData = async () => {
        const data = (posts || [])
            .map(post => {
                if (!post?.post_assessments) return null;
                const assessment = post.post_assessments.find(assessment => assessment?.type === stat_name);
                if (assessment) {
                    return {
                        ...assessment,
                        time: post?.time,
                        id: post?.id
                    };
                }
                return null;
            })
            .filter(item => item !== null)
            .filter(item => item?.rating !== null);
        
        setData(data);
        
        // Set initial time range based on data
        if (!timeRange) {
            setTimeRange(determineInitialTimeRange(data));
        }
    };

    useEffect(() => {
        if (Array.isArray(posts) && posts.length > 0) {
            fetchData();
        }
    }, [posts]);

    useEffect(() => {
        if (data.length > 0) {
            const sortedData = data.sort((a, b) => a.time - b.time);
            
            const now = new Date();
            const filteredData = sortedData.filter(d => {
                const date = new Date(d.time * 1000);
                if (timeRange === 'all') return true;
                const years = parseInt(timeRange);
                return date >= subYears(now, years);
            });

            const startDate = startOfMonth(new Date(filteredData[0]?.time * 1000) || subYears(now, 3));
            const endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(0);
            
            const allMonths = [];
            let currentMonth = startDate;
            while (currentMonth <= endDate) {
                allMonths.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0));
                currentMonth.setMonth(currentMonth.getMonth() + 1);
            }
            
            const counts = allMonths.map(month => {
                const monthData = filteredData.filter(d => 
                    new Date(d.time * 1000) <= month &&
                    new Date(d.time * 1000) > new Date(month.getFullYear(), month.getMonth() - 6, 1)
                );

                const bucketCounts = buckets.map(bucket => ({
                    ...bucket,
                    count: monthData.filter(d => d.rating >= bucket.min && d.rating < bucket.max).length
                }));

                const netCount = bucketCounts.reduce((acc, bucket) => {
                    const joinLeaveWeight = bucket.name.includes('Joiner') ? 1 : -1;
                    const strengthWeight = bucket.name.includes('Strong') ? 1 : 0.5;
                    return acc + (bucket.count * joinLeaveWeight * strengthWeight);
                }, 0);

                return {
                    x: format(month, 'yyyy-MM'),
                    bucketCounts,
                    netCount,
                    originalDataPoints: monthData,
                    observationCount: monthData.length
                };
            });

            let sufficientCounts = [];
            let rollingCount = 0;
            for (let i = 0; i < counts.length; i++) {
                rollingCount += counts[i].observationCount;
                if (rollingCount >= minObservations) {
                    sufficientCounts.push(counts[i]);
                } else if (sufficientCounts.length > 0) {
                    sufficientCounts.push(counts[i]);
                }
            }

            setChartData({
                labels: sufficientCounts.map(item => item.x),
                datasets: [
                    ...buckets.map((bucket, index) => ({
                        type: 'bar',
                        label: bucket.name,
                        data: sufficientCounts.map(count => {
                            const value = count.bucketCounts[index].count;
                            return bucket.name.includes('Leaver') ? -value : value;
                        }),
                        backgroundColor: bucket.color,
                        stack: 'Stack 0',
                    })),
                    {
                        type: 'line',
                        label: 'Net',
                        data: sufficientCounts.map(count => count.netCount),
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1,
                    }
                ]
            });
        }
    }, [data, buckets, minObservations, timeRange]);

    const handlePointClick = (event, elements) => {
        if (elements.length > 0) {
            const { datasetIndex, index } = elements[0];
            const month = chartData.labels[index];
            
            // Skip if clicking the net line chart
            if (datasetIndex >= buckets.length) return;
            
            const bucket = buckets[datasetIndex];
            const monthEnd = new Date(month);
            // Go back 6 months for the start date since we're using 6-month trailing periods
            const monthStart = new Date(monthEnd);
            monthStart.setMonth(monthStart.getMonth() - 6);
            
            const relevantPosts = (posts || []).filter(post => {
                if (!post?.post_assessments) return false;
                const assessment = post.post_assessments.find(a => a?.type === stat_name);
                if (!assessment) return false;
                
                const postDate = new Date(post.time * 1000);
                return postDate >= monthStart && 
                       postDate <= monthEnd && 
                       assessment.rating >= bucket.min && 
                       assessment.rating < bucket.max;
            }).map(post => {
                const assessment = post.post_assessments.find(a => a?.type === stat_name);
                return {
                    ...post,
                    rating: assessment?.rating,
                    confidence: assessment?.confidence,
                    explanation: assessment?.explanation,
                    ratingName: stat_name,
                    ratingType: stat_name
                };
            });

            setPostsReferenced(relevantPosts);
            setSelectedMonth(`${bucket.name} - ${format(monthStart, 'MMM yyyy')} to ${format(monthEnd, 'MMM yyyy')}`);
        }
    };

    return (
        <div>
            {chartData && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        <TimeRangeSelector
                            timeRange={timeRange}
                            onTimeRangeChange={setTimeRange}
                            periods={['1y', '3y', '5y', 'all']}
                        />
                    </div>
                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            scales: {
                                x: { stacked: true },
                                y: { 
                                    stacked: true,
                                    title: { display: true, text: 'Count' }
                                }
                            },
                            onClick: handlePointClick,
                        }}
                    />

                    {postsReferenced.length > 0 && (
                        <FilteredPostsDisplay 
                            posts={postsReferenced}
                            onClose={() => {
                                setPostsReferenced([]);
                                setSelectedMonth(null);
                            }}
                            title="Referenced Posts"
                            subtitle={selectedMonth ? `Posts from ${selectedMonth}` : null}
                            ratingDisplayName="Net Add Rating"
                            ratingColorScheme={{ high: 0, low: 0 }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default LeaverJoinerPlot;