import React, { useState, useEffect } from 'react';
import { makeTwitterSearchLink, cleanCorporateName } from '../functions/TwitterSearch';
import CTSDateRange from './CTSDateRange';
import ContentCard from './ContentCard';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import '../styles/CompanyTwitterSearch.css';

const CompanyTwitterSearch = ({ companyName, ticker }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showTimeDropdown, setShowTimeDropdown] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [timeDropdownText, setTimeDropdownText] = useState('Past Day');
    const [timeValue, setTimeValue] = useState(0);
    const [twitterLink, setTwitterLink] = useState('');
    const [dateMin, setDateMin] = useState(null);
    const [dateMax, setDateMax] = useState(null);
    const [blockHashtags, setBlockHashtags] = useState(true);
    const [blockLinks, setBlockLinks] = useState(false);
    const [onlyPopularPosts, setOnlyPopularPosts] = useState(true);
    const [banList, setBanList] = useState([]);
    const [banListString, setBanListString] = useState('');
    const [socialFilter, setSocialFilter] = useState(false);
    const [followersOnly, setFollowersOnly] = useState(false);
    const [controlLink, setControlLink] = useState('');

    useEffect(() => {
        // Set initial search term based on company info
        if (companyName && ticker) {
            const name = cleanCorporateName(companyName);
            const nameParts = name.split(' ');
            const nameQuery = nameParts.join(' OR ');
            const query = `$${ticker} OR "(${nameQuery})"`;
            setSearchTerm(query);
        }
    }, [companyName, ticker]);

    useEffect(() => {
        setControlLink('https://x.com/search?q=' + searchTerm + '&f=live');
    }, [searchTerm]);

    useEffect(() => {
        const link = makeTwitterSearchLink({
            searchTerm,
            date_min: dateMin,
            date_max: dateMax,
            custom_ban_list: banList,
            social_filter: socialFilter,
            followed_only: followersOnly,
            block_hashtags: blockHashtags,
            block_links: blockLinks,
            min_faves: onlyPopularPosts ? 10 : null
        });
        setTwitterLink(link);
    }, [searchTerm, dateMin, dateMax, banList, socialFilter, followersOnly, blockHashtags, blockLinks, onlyPopularPosts]);

    const handleSearch = () => {
        if (twitterLink) {
            window.open(twitterLink, '_blank');
        }
    };

    const handleBanListAdd = () => {
        if (banListString) {
            setBanList([...banList, banListString]);
            setBanListString('');
        }
    };

    return (
        <ContentCard title="Twitter Search">
            <div className="cts-search-layout">
                <div className="cts-search-copy">
                    <h3>Find Signal in the Noise</h3>
                    <p>
                        Cut through the spam and find real investment insights about {companyName} on Twitter/X.
                    </p>
                    <div className="cts-feature-list">
                        <div className="cts-feature-item">
                            <span style={{ color: '#1042c1', fontSize: '18px' }}>✓</span> Pre-filtered for {ticker} and common name variations
                        </div>
                        <div className="cts-feature-item">
                            ✓ Removes crypto spam, day trading noise, and bot activity
                        </div>
                        <div className="cts-feature-item">
                            ✓ Focus on substantive analysis and industry insights
                        </div>
                    </div>
                </div>

                <div className="cts-search-interface">
                    <input 
                        className="cts-input"
                        type="text"
                        placeholder="Search Twitter..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                    />
                    {showAdvanced && (
                        <div className="cts-advanced-search">
                            <div className="cts-filter-section">
                                <h4>Time Range</h4>
                                <CTSDateRange 
                                    setStartDate={setDateMin} 
                                    setEndDate={setDateMax}
                                />
                            </div>

                            <div className="cts-filter-section">
                                <h4>Filters</h4>
                                <div className="checkbox-section">
                                    <label className="checkbox-container">
                                        <input 
                                            type="checkbox" 
                                            checked={blockHashtags} 
                                            onChange={() => setBlockHashtags(!blockHashtags)} 
                                        />
                                        Filter out hashtags
                                    </label>
                                    <label className="checkbox-container">
                                        <input 
                                            type="checkbox" 
                                            checked={onlyPopularPosts} 
                                            onChange={() => setOnlyPopularPosts(!onlyPopularPosts)} 
                                        />
                                        Only popular posts
                                    </label>
                                    {/* Add other filters as needed */}
                                </div>
                            </div>

                            <div className="cts-filter-section">
                                <h4>Custom Blacklist</h4>
                                <div className="cts-banlist-inputs">
                                    <input 
                                        className="cts-exclude-input"
                                        type="text"
                                        placeholder="Add term to exclude"
                                        value={banListString}
                                        onChange={(e) => setBanListString(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleBanListAdd();
                                        }}
                                    />
                                    <button 
                                        onClick={handleBanListAdd}
                                        title="Add term to blacklist"
                                    >
                                        <ControlPointIcon style={{ fontSize: 20 }} />
                                    </button>
                                </div>
                                <div className="cts-banlist">
                                    {banList.map((term, index) => (
                                        <div key={index} className="cts-banlist-item">
                                            {term}
                                            <button onClick={() => {
                                                setBanList(banList.filter((_, i) => i !== index));
                                            }}>
                                                <CloseIcon />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="cts-search-actions">
                        <button 
                            className="cts-button-minimal"
                            onClick={() => setShowAdvanced(!showAdvanced)}
                        >
                            <TuneIcon />
                        </button>
                        <button 
                            className="cts-search-button"
                            onClick={handleSearch}
                        >
                            Search Twitter
                        </button>
                    </div>
                    {searchTerm && (
                        <div className="cts-control-link">
                            <a href={controlLink} target="_blank" rel="noreferrer">
                                See what we spared you from!
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </ContentCard>
    );
};

export default CompanyTwitterSearch; 