import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const CompanyAdminPanel = ({ 
    companyId, 
    companyData, 
    flaskServerAddress, 
    onTopicCreated,
    isAdmin 
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [deleteOldProducts, setDeleteOldProducts] = useState(false);
    const [newTopicData, setNewTopicData] = useState({
        topic: '',
        relationship: '',
        additional_text: '',
        alt_name: ''
    });

    const handleCreateTopic = async () => {
        try {
            const response = await fetch(`${flaskServerAddress}create-company-topic/${companyId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newTopicData),
            });

            const data = await response.json();
            
            if (response.ok) {
                // Reset form
                setNewTopicData({
                    topic: '',
                    relationship: '',
                    additional_text: '',
                    alt_name: ''
                });
                
                // Notify parent to refresh data
                onTopicCreated();
                
                alert('Topic created successfully!');
            } else {
                console.error('Error creating topic:', data.error);
                alert('Failed to create topic: ' + data.error);
            }
        } catch (error) {
            console.error('Error creating topic:', error);
            alert('Failed to create topic');
        }
    };

    const handleGenerateDetails = async () => {
        try {
            const response = await fetch(
                `${flaskServerAddress}generate-company-details/${companyId}/?delete_old_products=${deleteOldProducts}`
            );
            const data = await response.json();
            
            if (response.ok) {
                // Notify parent to refresh data
                onTopicCreated();
                alert('Company details generated successfully!');
            } else {
                console.error('Error generating company details:', data.error);
                alert('Failed to generate company details');
            }
        } catch (error) {
            console.error('Error generating company details:', error);
            alert('Failed to generate company details');
        }
    };

    const handleDeleteTopic = async (topicId) => {
        if (!window.confirm('Are you sure you want to delete this topic?')) {
            return;
        }

        try {
            const response = await fetch(`${flaskServerAddress}delete-company-topic/${companyId}/${topicId}/`, {
                method: 'DELETE',
            });

            if (response.ok) {
                onTopicCreated(); // Refresh the data
                alert('Topic deleted successfully!');
            } else {
                const data = await response.json();
                console.error('Error deleting topic:', data.error);
                alert('Failed to delete topic: ' + data.error);
            }
        } catch (error) {
            console.error('Error deleting topic:', error);
            alert('Failed to delete topic');
        }
    };

    if (!isAdmin) return null;

    return (
        <>
            <button 
                className="btn btn-icon btn-icon-sm"
                onClick={() => setIsOpen(true)}
            >
                <SettingsIcon />
            </button>

            <Dialog 
                open={isOpen} 
                onClose={() => setIsOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Admin Panel - Topic Management</DialogTitle>
                <DialogContent>
                    <div className="admin-panel">
                        <div className="admin-actions">
                            <div className="generate-details-section">
                                <FormControl component="fieldset" style={{ marginBottom: '16px' }}>
                                    <label className="delete-products-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={deleteOldProducts}
                                            onChange={(e) => setDeleteOldProducts(e.target.checked)}
                                        />
                                        Delete old products when generating
                                    </label>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleGenerateDetails}
                                    fullWidth
                                >
                                    Generate Company Details
                                </Button>
                            </div>
                        </div>

                        <div className="create-topic-form">
                            <h3>Create New Topic</h3>
                            <TextField
                                fullWidth
                                label="Topic Name"
                                value={newTopicData.topic}
                                onChange={(e) => setNewTopicData({
                                    ...newTopicData, 
                                    topic: e.target.value
                                })}
                                margin="normal"
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Relationship</InputLabel>
                                <Select
                                    value={newTopicData.relationship}
                                    onChange={(e) => setNewTopicData({
                                        ...newTopicData, 
                                        relationship: e.target.value
                                    })}
                                >
                                    <MenuItem value="product">Product</MenuItem>
                                    <MenuItem value="competitor">Competitor</MenuItem>
                                    <MenuItem value="general">General</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                label="Additional Text"
                                value={newTopicData.additional_text}
                                onChange={(e) => setNewTopicData({
                                    ...newTopicData, 
                                    additional_text: e.target.value
                                })}
                                margin="normal"
                                multiline
                                rows={2}
                            />
                            <TextField
                                fullWidth
                                label="Alternative Name"
                                value={newTopicData.alt_name}
                                onChange={(e) => setNewTopicData({
                                    ...newTopicData, 
                                    alt_name: e.target.value
                                })}
                                margin="normal"
                            />
                        </div>

                        <div className="existing-topics">
                            <h3>Existing Topics</h3>
                            <div className="topics-list">
                                {companyData.topics?.map((topic) => (
                                    <div key={topic.id} className="topic-item">
                                        <strong>{topic.topic}</strong>
                                        <span>ID: {topic.id}</span>
                                        <span>Relationship: {topic.relationship}</span>
                                        <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() => handleDeleteTopic(topic.id)}
                                            title="Delete Topic"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>Close</Button>
                    <Button 
                        onClick={handleCreateTopic}
                        variant="contained" 
                        color="primary"
                        disabled={!newTopicData.topic || !newTopicData.relationship}
                    >
                        Create Topic
                    </Button>
                </DialogActions>
            </Dialog>

            <style jsx>{`

                .admin-panel {
                    padding: 16px;
                }

                .topics-list {
                    margin-top: 16px;
                }

                .topic-item {
                    padding: 12px;
                    border: 1px solid #e0e0e0;
                    border-radius: 4px;
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #f5f5f5;
                    gap: 12px;
                }

                .topic-item span {
                    color: #666;
                    font-size: 0.9em;
                }

                .create-topic-form {
                    margin-bottom: 24px;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #e0e0e0;
                }

                .generate-details-section {
                    margin-bottom: 24px;
                }

                .delete-products-checkbox {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 8px;
                    cursor: pointer;
                }

                .delete-products-checkbox input {
                    cursor: pointer;
                }
            `}</style>
        </>
    );
};

export default CompanyAdminPanel; 