import React, { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { motion, AnimatePresence } from 'framer-motion';
import supabase from './supabaseClient';
import { useAuth } from './AuthContext';

const NoDataMessage = ({ 
  topic, 
  companyName,
  companyId,
  companyTicker,
  userId,
  dataType,
  onRequestData 
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { session } = useAuth();
  const defaultMessage = `Coming Soon! Since Scuttlebutt is still in beta, some company pages are not fully populated. But, if you click below, we'll get the data within a few days!`;
  const successMessage = `Request received! We'll analyze the data and notify you when it's ready.`;
  
  const handleRequest = async () => {
    if (!userId || !session) {
      console.error('No user ID or session');
      return;
    }

    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from('data_requests')
        .insert([
          {
            user_id: userId,
            company_id: companyId,
            company_ticker: companyTicker || null,
            data_type: dataType,
          }
        ])
        .select();

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      if (onRequestData) {
        await onRequestData(topic, companyName);
      }
      
      setIsSuccess(true);
    } catch (err) {
      console.error('Error logging data request:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="no-data-container">
      <AnimatePresence mode="wait">
        {!isSuccess ? (
          <motion.div 
            className="no-data-message"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="request"
          >
            <p>{defaultMessage}</p>
            <Button
              variant="contained"
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <EmailIcon />}
              onClick={handleRequest}
              disabled={isSubmitting || !userId || !session}
            >
              {isSubmitting ? 'Requesting...' : 'Request Data'}
            </Button>
          </motion.div>
        ) : (
          <motion.div 
            className="success-message"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ 
              type: "spring",
              stiffness: 200,
              damping: 20
            }}
            key="success"
          >
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ 
                delay: 0.2,
                type: "spring",
                stiffness: 200,
                damping: 15
              }}
            >
              <CheckCircleIcon className="success-icon" />
            </motion.div>
            <p>{successMessage}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NoDataMessage; 