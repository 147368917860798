import React, { useEffect } from 'react';
import PostDisplay from './PostDisplay';
import CloseIcon from '@mui/icons-material/Close';
import '../styles/FilteredPostsDisplay.css';

const FilteredPostsDisplay = ({ 
    posts, 
    onClose, 
    title = "Referenced Posts",
    subtitle,
    showBookmarkButton = false,
    ratingDisplayName,
    id = 'filtered-posts',
    autoScroll = true,
    ratingColorScheme
}) => {
    useEffect(() => {
        if (autoScroll && posts.length > 0) {
            const postsSection = document.getElementById(id);
            if (postsSection) {
                const padding = 140;
                const targetPosition = postsSection.getBoundingClientRect().top + window.pageYOffset - padding;
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [posts, id, autoScroll]);

    if (posts.length === 0) return null;

    return (
        <div id={id} className="filtered-posts-section">
            <div className="filtered-posts-header">
                <div className="filtered-posts-titles">
                    <h3 className="filtered-posts-title">{title}</h3>
                    {subtitle && <span className="filtered-posts-subtitle">•</span>}
                    {subtitle && <p className="filtered-posts-subtitle">{subtitle}</p>}
                </div>
                <button 
                    onClick={onClose} 
                    className="filtered-posts-close-button"
                    aria-label="Close posts"
                >
                    <CloseIcon />
                </button>
            </div>
            <PostDisplay 
                data={posts}
                showBookmarkButton={showBookmarkButton}
                ratingDisplayName={ratingDisplayName}
                ratingColorScheme={ratingColorScheme}
            />
        </div>
    );
};

export default FilteredPostsDisplay; 