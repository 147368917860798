import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../components/AuthContext';
import CompanyCard from '../components/CompanyCard'; // We'll create this

function Homepage() {
    const [loading, setLoading] = useState(false);
    const [featuredCompanies, setFeaturedCompanies] = useState([]);
    const { serverAddress } = useAuth();
    const navigate = useNavigate();

    const isMobile = window.innerWidth <= 768;
    const searchWidth = isMobile ? '90%' : '70%';
    
    useEffect(() => {
        document.title = 'Scuttlebutt Research';
        fetchFeaturedCompanies();
    }, []);

    const fetchFeaturedCompanies = async () => {
        try {
            const response = await fetch(`${serverAddress}api/featured-companies`);
            const data = await response.json();
            setFeaturedCompanies(data);
        } catch (error) {
            console.error('Error fetching featured companies:', error);
        }
    };

    const handleSearch = (newQuery) => {
        navigate(`/search?q=${encodeURIComponent(newQuery)}`);
    };

    return (
        <div className="homepage">
            <div className="homepage-hero">
                <h1 className="homepage-title">Discover Customer Intelligence</h1>
                <p className="homepage-subtitle">
                    Search millions of customer conversations to understand product sentiment, 
                    competitive dynamics, and market trends
                </p>
                <div className="homepage-search" style={{width: searchWidth}}>
                    <SearchBar 
                        onSearch={handleSearch}
                        setLoading={setLoading}
                    />
                </div>
            </div>

            {loading ? (
                <div className="progress-indicator">
                    <CircularProgress />
                </div>
            ) : (
                <div className="homepage-content">
                    <div className="featured-companies">
                        <h2>Featured Intelligence</h2>
                        <div className="company-grid">
                            {featuredCompanies.map(company => (
                                <CompanyCard 
                                    key={company.id}
                                    company={company}
                                />
                            ))}
                        </div>
                    </div>

                    <div className="recent-insights">
                        <h2>Recent Market Insights</h2>
                        {/* We could add a feed of recent interesting findings */}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Homepage; 