import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import StockChart from './StockChart';

const StockChartContainer = ({ symbol, timePeriod = '3y' }) => {
    const [allData, setAllData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { flaskServerAddress } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                // Fetch all available data
                const response = await fetch(`${flaskServerAddress}stock-chart/${symbol}/all/`);
                if (!response.ok) throw new Error('Failed to fetch stock data');
                const jsonData = await response.json();
                setAllData(jsonData.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (symbol) {
            fetchData();
        }
    }, [symbol, flaskServerAddress]);

    const filterDataByTimePeriod = (data, period) => {
        if (!data) return null;
        
        const now = new Date();
        const cutoffDate = new Date();
        
        switch (period) {
            case '1y':
                cutoffDate.setFullYear(now.getFullYear() - 1);
                break;
            case '3y':
                cutoffDate.setFullYear(now.getFullYear() - 3);
                break;
            case '5y':
                cutoffDate.setFullYear(now.getFullYear() - 5);
                break;
            case '10y':
                cutoffDate.setFullYear(now.getFullYear() - 10);
                break;
            case 'all':
                return data;
            default:
                cutoffDate.setFullYear(now.getFullYear() - 3); // Default to 3y
        }

        return data.filter(item => new Date(item.date) >= cutoffDate);
    };

    if (loading) return <div className="progress-indicator"><CircularProgress /></div>;
    if (error) return <div className="error-message">Error loading stock data: {error}</div>;
    if (!allData) return null;

    const filteredData = filterDataByTimePeriod(allData, timePeriod);
    return <StockChart data={filteredData} />;
};

export default StockChartContainer; 