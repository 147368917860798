import React, {useState, useEffect, useRef} from "react";
import { useApiDataContext } from './ApiDataContext';
import Modal from './Modal';

function NewBookmarkFolder({newFolderModalIsOpen, closeNewFolderModal, newFolderPostId=null, parentId=null}) {
    const { createFolder } = useApiDataContext();
    const [newFolderName, setNewFolderName] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (newFolderModalIsOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [newFolderModalIsOpen]);

    const handleChange = (event) => {
        setNewFolderName(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleCreate();
        }
    }

    const handleCreate = async () => {
        if (newFolderName === '') {
            alert('Please choose a name for the folder!');
            return;
        }
        createFolder(newFolderName, newFolderPostId, parentId);
        setNewFolderName('');
        closeNewFolderModal();
    }

    return (
        <Modal
            isOpen={newFolderModalIsOpen}
            onClose={closeNewFolderModal}
            title="Create New Folder"
        >
            <div className="form-container">
                <div className="form-input">
                    <input 
                        type='text'
                        placeholder='Folder Name'
                        ref={inputRef}
                        value={newFolderName}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                    />
                </div>
                <button 
                    className="btn btn-primary btn-full btn-rounded"
                    onClick={handleCreate}
                >
                    Create
                </button>
            </div>
        </Modal>
    );
}

export default NewBookmarkFolder;