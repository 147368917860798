import { isQuestion } from '../utils/searchUtils';

class SearchService {
    constructor(flaskServerAddress, serverAddress) {
        this.flaskServerAddress = flaskServerAddress;
        this.serverAddress = serverAddress;
        this.hybridPath = `${flaskServerAddress}hybrid-search`;
    }

    async search(query, options = {}) {
        const { 
            startDate = null, 
            endDate = null,
            userId = null,
            shouldLogSearch = true
        } = options;

        if (!query) return null;

        try {
            if (isQuestion(query)) {
                return await this.handleQuestionSearch(query);
            } else {
                return await this.handleRegularSearch(query, startDate, endDate);
            }
        } finally {
            if (shouldLogSearch) {
                await this.logSearch(query, userId);
            }
        }
    }

    async handleQuestionSearch(query) {
        const encodedQuery = encodeURIComponent(query);
        const response = await fetch(`${this.flaskServerAddress}answer-question/${encodedQuery}/`);
        const data = await response.json();
        
        if (data.status === 'success') {
            return {
                type: 'question',
                answer: data.answer,
                posts: data.posts_used,
                noResults: false
            };
        }
        return null;
    }

    async handleRegularSearch(query, startDate, endDate) {
        const encodedQuery = encodeURIComponent(query);
        const start = startDate ? startDate.valueOf() / 1000 : 0;
        const end = endDate ? endDate.valueOf() / 1000 : 0;
        
        const url = `${this.hybridPath}/${encodedQuery}${start || end ? `/${start}/${end}` : ''}`;
        
        const response = await fetch(url);
        const data = await response.json();
        
        return {
            type: 'regular',
            posts: data,
            noResults: data.length === 0
        };
    }

    async logSearch(query, userId) {
        try {
            const response = await fetch(this.serverAddress + 'log-search/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: query || '',
                    user_id: userId || null,
                }),
            });
            
            if (response.status !== 200) {
                console.error('Error logging search:', response);
            }
        } catch (error) {
            console.error('Error logging search:', error);
        }
    }
}

export default SearchService; 