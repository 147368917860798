import React from 'react';

const PrivacyPolicy = () => {
    const containerStyle = {
        padding: '40px 20px',
        maxWidth: '800px',
        margin: '0 auto',
        lineHeight: '1.6',
        color: '#333',
    };

    const sectionStyle = {
        marginBottom: '32px'
    };

    const headingStyle = {
        marginTop: '32px',
        marginBottom: '16px',
        color: '#1a1a1a'
    };

    const subHeadingStyle = {
        marginTop: '24px',
        marginBottom: '12px',
        color: '#333'
    };

    const listStyle = {
        marginLeft: '24px',
        marginBottom: '16px',
        marginTop: '8px',
        listStyleType: 'disc',
        paddingLeft: '16px'
    };

    const listItemStyle = {
        marginBottom: '8px',
        paddingLeft: '8px'
    };

    const titleStyle = {
        fontSize: '32px',
        marginBottom: '8px',
        color: '#1a1a1a'
    };

    const dateStyle = {
        color: '#666',
        marginBottom: '32px'
    };

    return (
        <div className="container" style={containerStyle}>
            <h1 style={titleStyle}>SCUTTLEBUTT RESEARCH, INC. PRIVACY POLICY</h1>
            <p style={dateStyle}>Last Updated: December 5, 2024</p>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Introduction</h2>
                <p>Scuttlebutt Research, Inc. ("Scuttlebutt Research," "we," "our," or "us") is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and safeguard your information when you use our investment research software and services, including our website at www.scuttlebuttresearch.com (collectively, the "Service").</p>
                <p>By accessing or using our Service, you agree to this Privacy Policy. If you do not agree with our policies and practices, please do not use our Service.</p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Information We Collect</h2>
                
                <h3 style={subHeadingStyle}>Personal Information</h3>
                <p>We collect the following types of personal information that you provide to us:</p>
                <ul style={listStyle}>
                    {['Name', 'Email address', 'Place of work', 'Account credentials', 'Usage data and activity on our site']
                        .map((item, index) => (
                            <li key={index} style={listItemStyle}>{item}</li>
                        ))}
                </ul>

                <h3 style={subHeadingStyle}>Automatically Collected Information</h3>
                <p>When you visit our website, we automatically collect certain information about your device and usage of the Service, including:</p>
                <ul style={listStyle}>
                    <li style={listItemStyle}>IP address</li>
                    <li style={listItemStyle}>Browser type</li>
                    <li style={listItemStyle}>Device type</li>
                    <li style={listItemStyle}>Time spent on pages</li>
                    <li style={listItemStyle}>Pages visited</li>
                    <li style={listItemStyle}>Other usage statistics</li>
                </ul>
                <p>We use Google Analytics to help us understand how our users interact with the Service. Google Analytics uses cookies and similar tracking technologies to collect and analyze information about the use of the Service.</p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>How We Use Your Information</h2>
                <p>We use the information we collect to:</p>
                <ul style={listStyle}>
                    <li style={listItemStyle}>Provide and maintain our Service</li>
                    <li style={listItemStyle}>Process your transactions</li>
                    <li style={listItemStyle}>Send you important Service announcements and updates</li>
                    <li style={listItemStyle}>Respond to your comments and questions</li>
                    <li style={listItemStyle}>Analyze and improve our Service</li>
                    <li style={listItemStyle}>Protect the security and integrity of our Service</li>
                    <li style={listItemStyle}>Comply with legal obligations</li>
                </ul>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Data Security</h2>
                <p>We implement appropriate technical and organizational security measures to protect your personal information from accidental loss and unauthorized access, use, alteration, or disclosure. However, please note that no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Cookies</h2>
                <p>We use cookies to collect information about your browsing activities. You can set your browser to refuse all or some browser cookies or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the Service may become inaccessible or not function properly.</p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Third-Party Services</h2>
                <p>Our Service uses Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies to help us analyze how users use our Service. The information generated by the cookie about your use of our Service will be transmitted to and stored by Google on servers in the United States. You can learn more about how Google uses your data at: https://policies.google.com/privacy.</p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Children's Privacy</h2>
                <p>Our Service is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13. If you are under 13, please do not provide any information on our Service.</p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Your Rights</h2>
                <p>You have the right to:</p>
                <ul style={listStyle}>
                    <li style={listItemStyle}>Access your personal information</li>
                    <li style={listItemStyle}>Correct inaccurate personal information</li>
                    <li style={listItemStyle}>Request deletion of your personal information</li>
                    <li style={listItemStyle}>Opt-out of certain data collection and use</li>
                    <li style={listItemStyle}>Withdraw consent where applicable</li>
                </ul>
                <p>To exercise these rights, please contact us using the information provided below.</p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date. You are advised to review this Privacy Policy periodically for any changes.</p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
                <p>
                    Scuttlebutt Research, Inc.<br />
                    Email: acastle@scuttlebuttresearch.com
                </p>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>California Privacy Rights</h2>
                <p>If you are a California resident, you have additional rights under the California Consumer Privacy Act (CCPA), including:</p>
                <ul style={listStyle}>
                    <li style={listItemStyle}>The right to know what personal information we collect and how we use it</li>
                    <li style={listItemStyle}>The right to delete your personal information</li>
                    <li style={listItemStyle}>The right to opt-out of the sale of your personal information (note: we do not sell personal information)</li>
                    <li style={listItemStyle}>The right to non-discrimination for exercising your CCPA rights</li>
                </ul>
            </section>

            <section style={sectionStyle}>
                <h2 style={headingStyle}>International Users</h2>
                <p>If you are accessing our Service from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located. By using our Service, you consent to such transfer and processing of your data.</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy; 