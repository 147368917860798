import React from 'react';
import { useNavigate } from 'react-router-dom';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const CompanyCard = ({ company }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/company/${company.id}`);
    };

    return (
        <div className="company-card" onClick={handleClick}>
            {company.logo && (
                <img 
                    src={company.logo} 
                    alt={`${company.name} logo`} 
                    className="company-card-logo"
                />
            )}
            <div className="company-card-content">
                <h3>{company.name}</h3>
                <div className="company-metrics">
                    {company.nps_trend && (
                        <div className="metric">
                            <span>NPS Trend</span>
                            {company.nps_trend > 0 ? (
                                <TrendingUpIcon className="trend-up" />
                            ) : (
                                <TrendingDownIcon className="trend-down" />
                            )}
                        </div>
                    )}
                    {company.recent_posts && (
                        <div className="metric">
                            <span>{company.recent_posts} recent mentions</span>
                        </div>
                    )}
                </div>
                <p className="company-highlight">{company.highlight}</p>
            </div>
        </div>
    );
}

export default CompanyCard; 