import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

const StockChart = ({ data }) => {
    if (!data || data.length === 0) return null;

    // Find indices where the year changes to use as ticks
    const yearChangeIndices = data.reduce((acc, item, index) => {
        if (index === 0) return [index]; // Always include first point
        
        const currentDate = new Date(item.date);
        const prevDate = new Date(data[index - 1].date);
        
        if (currentDate.getFullYear() !== prevDate.getFullYear()) {
            acc.push(index);
        }
        return acc;
    }, []);

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.getFullYear().toString();
    };

    const formatTooltipDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const formatPrice = (price) => `$${parseFloat(price).toFixed(2)}`;

    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <LineChart data={data} margin={{ top: 10, right: 30, bottom: 20, left: 20 }}>
                    <Line 
                        type="monotone" 
                        dataKey="adjusted_close" 
                        stroke="#0066cc" 
                        dot={false}
                        strokeWidth={1.5}
                        animationDuration={500}
                    />
                    <XAxis 
                        dataKey="date" 
                        tickFormatter={formatDate}
                        ticks={yearChangeIndices.map(index => data[index].date)}
                        tick={{ fill: '#666666', fontSize: 12 }}
                        tickLine={{ stroke: '#cccccc' }}
                        axisLine={{ stroke: '#cccccc' }}
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis 
                        domain={['auto', 'auto']}
                        tickFormatter={formatPrice}
                        tick={{ fill: '#666666', fontSize: 12 }}
                        tickLine={{ stroke: '#cccccc' }}
                        axisLine={{ stroke: '#cccccc' }}
                    />
                    <Tooltip 
                        labelFormatter={formatTooltipDate}
                        formatter={(value) => [formatPrice(value), 'Price']}
                        contentStyle={{
                            backgroundColor: 'rgba(255, 255, 255, 0.98)',
                            border: '1px solid #e0e0e0',
                            borderRadius: '6px',
                            padding: '8px 12px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            fontSize: '13px'
                        }}
                        cursor={{ stroke: '#0066cc', strokeWidth: 1, strokeDasharray: '4 4' }}
                    />
                    <CartesianGrid 
                        strokeDasharray="3 3" 
                        stroke="#f0f0f0" 
                        vertical={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default StockChart; 