import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';

const SearchPreviewResultCompany = ({ company, isSelected, onClick }) => {
    return (
        <div 
            className={`search-preview-item ${isSelected ? 'selected' : ''}`}
            onClick={() => onClick?.(company)}
        >
            <div className="company-icon">
                {company.logo ? (
                    <img 
                        src={company.logo} 
                        alt={company.name} 
                        style={{ width: '20px', height: '20px', objectFit: 'contain' }}
                    />
                ) : (
                    <BusinessIcon style={{ fontSize: '16px' }} />
                )}
            </div>
            <div className="company-info">
                <div className="company-name">
                    {company.name}
                    {company.ticker && (
                        <span className="company-ticker-badge">{company.ticker}</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchPreviewResultCompany;
