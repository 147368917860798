import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const ProductSelector = ({ 
    products, 
    selectedProduct, 
    onProductChange,
    label = "Product",
    className = "",
    hideAllOption = false
}) => {
    if (!products || products.length === 0) return null;

    // Sort products by priority (low to high)
    const sortedProducts = [...products].sort((a, b) => {
        // Handle undefined priorities - put them at the end
        if (a.priority === undefined && b.priority === undefined) return 0;
        if (a.priority === undefined) return 1;
        if (b.priority === undefined) return -1;
        return a.priority - b.priority;
    });

    return (
        <FormControl className={className} size="small" variant="outlined">
            <InputLabel id="product-select-label" shrink={true}>{label}</InputLabel>
            <Select
                labelId="product-select-label"
                id="product-select"
                value={selectedProduct || ''}
                label={label}
                onChange={(e) => onProductChange(e.target.value)}
                displayEmpty
                notched
                sx={{ 
                    minWidth: 200,
                    '& .MuiSelect-select': {
                        backgroundColor: 'white'
                    }
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 300
                        }
                    }
                }}
            >
                {!hideAllOption && (
                    <MenuItem value="">
                        <em>All Products</em>
                    </MenuItem>
                )}
                {sortedProducts.map((product) => (
                    <MenuItem key={product.topic} value={product.topic}>
                        {product.topic}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ProductSelector; 