import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { Tooltip, Snackbar } from '@mui/material';

const QuestionAnswer = ({ answer, supportingPosts }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  if (!answer) return null;

  const handleCopy = () => {
    // Create a temporary div to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = answer;
    
    // Get all links from the answer and extract their fragment identifiers
    const links = tempDiv.getElementsByTagName('a');
    const citedIds = Array.from(links)
      .map(link => link.href.split('#')[1])
      .filter(Boolean); // Remove any undefined/empty values
    
    // Start with the main answer
    let textToCopy = tempDiv.textContent; // This gets text without HTML

    // Add cited evidence
    if (supportingPosts && supportingPosts.length > 0) {
      const citedPosts = supportingPosts.filter(post => 
        citedIds.includes(post.id)
      );

      if (citedPosts.length > 0) {
        textToCopy += '\n\nCited Sources:\n';
        citedPosts.forEach((post, index) => {
          textToCopy += `\n${index + 1}. ${post.title || 'Untitled Post'}\n`;
          if (post.content) {
            textToCopy += `${post.content.replace(/<[^>]+>/g, '')}\n`; // Full content
          }
          if (post.url) {
            textToCopy += `Source: ${post.url}\n`;
          }
          textToCopy += '\n---\n';
        });
      }
    }

    // Copy to clipboard
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setShowSnackbar(true);
        // Reset the icon after 1.5 seconds
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <div className='standard-width' style={{width: '70%', margin: '20px auto'}}>
      <div className='card summary-container' >
        <div className='split'>
            <h4>Answer</h4>
            <Tooltip title={isCopied ? "Copied!" : "Copy answer with cited sources"} placement="top">
              <button 
                className={`btn-icon btn-icon-sm btn-no-bg ${isCopied ? 'success' : ''}`}
                onClick={handleCopy}
              >
                {isCopied ? (
                  <CheckIcon fontSize="small" style={{ color: '#4CAF50' }} />
                ) : (
                  <ContentCopyIcon fontSize="small" />
                )}
              </button>
            </Tooltip>
        </div>
        <div className='summary-content'
            dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
        message="Copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  );
};

export default QuestionAnswer; 