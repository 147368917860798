import { useContext, useState, useRef } from 'react';
import { useAuth } from './AuthContext';
import { ModalContext } from "../App";
import SearchIcon from '@mui/icons-material/Search';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApiDataContext } from './ApiDataContext';
import SearchPreviewNew from './SearchPreviewNew';
import '../styles/HeaderBar.css';

// Move routes to constants outside component
const ROUTES_WITH_NO_HEADER = new Set([
    '/',
    '/twitter-landing',
    '/advanced-feature-pricing',
    '/twitter-pricing',
    '/twitter-search-tool',
    '/payment-confirmation',
    '/witg',
    '/witg-signup',
    '/witg-intake'
]);

// Add new constant for routes that should use the landing page header
const ROUTES_WITH_LANDING_HEADER = new Set([
    '/',
    '/case-study/NPS',
    '/case-study/leaver-joiner'
]);

const ROUTES_TO_HIDE_SIGNUP = new Set([
    '/book-demo'
]);

// Add search page to routes that should hide search
const ROUTES_WITH_HIDDEN_SEARCH = new Set([
    '/search',
    '/home'
]);

function HeaderBar({searchPage}) {
    const { userId } = useAuth();
    const modalContext = useContext(ModalContext);
    const {isMobile} = useApiDataContext();
    const location = useLocation();
    const navigate = useNavigate();
    const searchInputRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const handleClearSearch = () => {
        setSearchQuery('');
    };

    const handleSearch = (inputQuery) => {
        const queryToSearch = inputQuery || searchQuery;
        if (!queryToSearch) return;
        
        navigate(`/search?q=${encodeURIComponent(queryToSearch)}`);
        setSearchQuery('');
        setIsFocused(false);
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
    };

    const handleCompanyClick = (company) => {
        // Start navigation immediately
        navigate(`/company/${company.id}`);
        
        // Handle UI cleanup
        setIsFocused(false);
        
        // Delay blur until after animation
        setTimeout(() => {
            if (searchInputRef.current) {
                searchInputRef.current.blur();
            }
        }, 200);
    };

    // Early return if we're on a route that shouldn't show the header
    // AND it's not a case study page
    if (ROUTES_WITH_NO_HEADER.has(location.pathname) && 
        !location.pathname.startsWith('/case-study/')) {
        return null;
    }

    // If this is a landing page or case study, use the landing page header style
    if (ROUTES_WITH_LANDING_HEADER.has(location.pathname) || 
        location.pathname.startsWith('/case-study/')) {
        return null;
    }

    const padding = isMobile ? '5px' : '20px';
    const shouldShowSearch = !ROUTES_WITH_HIDDEN_SEARCH.has(location.pathname);

    return (
        <div className='header-bar-container'>
            <div className='header-bar'>
                <div className='toolbar-buttons-left'>
                    <div className='buttons-left'>
                        <div className="navbar-logo-search">
                            <h1 className='navbar-header-title'>
                                <a href="/">
                                    <img src='/img/favicon-navbar.png' alt="Logo" />
                                </a>
                            </h1>
                            {userId && shouldShowSearch && (
                                <div className={`header-search-container ${isFocused ? 'focused' : ''}`}>
                                    <div className={`search-icon-wrapper ${isFocused ? 'focused' : ''}`}>
                                        <SearchIcon />
                                    </div>
                                    <input
                                        ref={searchInputRef}
                                        type="text"
                                        className="header-search-input"
                                        placeholder="Search companies..."
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        onFocus={() => setIsFocused(true)}
                                        onBlur={() => setIsFocused(false)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSearch();
                                            }
                                        }}
                                    />
                                    <SearchPreviewNew
                                        query={searchQuery}
                                        parentRef={searchInputRef}
                                        onlyShowCompanySearch={false}
                                        handleClick={handleCompanyClick}
                                        handleSearch={handleSearch}
                                        onClear={handleClearSearch}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='toolbar-buttons-right' style={{paddingRight: padding}}>
                    {userId && (
                        <div className='buttons-with-user'>
                            <div className='my-bookmarks-section'>
                                <a href='/bookmarks'>
                                    <div className='toolbar-button centered-icon-button'>
                                        <div className="button-icon">
                                            <BookmarkBorderIcon />
                                        </div>
                                        <div className={`icon-button-label ${!isMobile ? 'label-padded' : ''}`}>{isMobile ? '' : 'Bookmarks'}</div>
                                    </div>
                                </a>

                                <a href='/account'>
                                    <div className='toolbar-button centered-icon-button'>
                                        <div className="button-icon">
                                            <AccountCircleIcon />
                                        </div>
                                        <div className={`icon-button-label ${!isMobile ? 'label-padded' : ''}`}>{isMobile ? '' : 'Account'}</div>
                                    </div>
                                </a>
                            </div> 
                        </div>
                    )}
                    {!userId && !ROUTES_TO_HIDE_SIGNUP.has(location.pathname) && (
                        <button 
                            className='toolbar-button sign-up-button'
                            onClick={() => modalContext.openLoginModal(true)}
                        >
                            Sign Up
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default HeaderBar;