import React, { useState } from 'react';
import PostV2 from './PostV2';

const POSTS_PER_PAGE = 20;

function PostDisplay({ 
    data, 
    showBookmarkButton, 
    noResults, 
    query, 
    ratingThresholds, 
    ratingDisplayName, 
    sources, 
    ratingColorScheme,
    disablePagination = false,
    linkTo = null,
    linkText = "View More",
    initialPostCount = POSTS_PER_PAGE,
    onLinkClick = null
}) {
    const [displayCount, setDisplayCount] = useState(initialPostCount);

    // Dedupe data array based on post IDs
    const dedupedData = [...new Map(data.map(post => [post.id, post])).values()];
    
    // Sort posts by confidence * abs(rating), putting unrated/unconfident posts at the bottom
    const sortedData = dedupedData.sort((a, b) => {
        const scoreA = (a.rating !== null && a.confidence !== null) ? Math.abs(a.rating) * a.confidence : -1;
        const scoreB = (b.rating !== null && b.confidence !== null) ? Math.abs(b.rating) * b.confidence : -1;
        return scoreB - scoreA;
    });

    const displayedPosts = disablePagination ? sortedData : sortedData.slice(0, displayCount);
    const hasMorePosts = !disablePagination && sortedData.length > displayCount;

    const handleViewMore = () => {
        setDisplayCount(prevCount => prevCount + POSTS_PER_PAGE);
    };

    const handleLink = () => {
        if (onLinkClick) {
            onLinkClick();
        } else if (linkTo) {
            window.location.href = linkTo;
        }
    };
    
    return (
        <div className="post-display">
            {sortedData.length > 0 && (
                <div>
                    {displayedPosts.map(post => (
                        <PostV2
                            key={post.id}
                            post={post}
                            showBookmarkButton={showBookmarkButton}
                            query={query}
                            ratingThresholds={ratingThresholds}
                            ratingDisplayName={ratingDisplayName}
                            sources={sources}
                            isTopLevel={true}
                            ratingColorScheme={ratingColorScheme}
                        />
                    ))}
                    {(hasMorePosts || linkTo || onLinkClick) && (
                        <div className="view-more-container">
                            {(linkTo || onLinkClick) ? (
                                <button 
                                    className="view-more-button"
                                    onClick={handleLink}
                                >
                                    {linkText}
                                </button>
                            ) : hasMorePosts && (
                                <button 
                                    className="view-more-button"
                                    onClick={handleViewMore}
                                >
                                    View More
                                </button>
                            )}
                        </div>
                    )}
                </div>
            )}
            {noResults && (
                <div className='no-results'>
                    <p>No results found.</p>
                </div>
            )}
        </div>
    );
}

export default PostDisplay;
