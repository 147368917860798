import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../components/AuthContext';
import { useApiDataContext } from '../components/ApiDataContext';
import StockChartContainer from '../components/StockChartContainer';
import FundamentalsChart from '../components/FundamentalsChart';
import FilingsTable from '../components/FilingsTable';
import NPSPlot from '../components/NPSPlot';
import LeaverJoinerPlot from '../components/LeaverJoinerPlot';
import CompetitorChurnPlot from '../components/CompetitorChurnPlot';
import CompetitiveComparisonPlot from '../components/CompetitiveComparisonPlot';
import PricingPlot from '../components/PricingPlot';
import NoDataMessage from '../components/NoDataMessage';
import TimeRangeSelector from '../components/TimeRangeSelector';
import PostDisplay from '../components/PostDisplay';
import ContentCard from '../components/ContentCard';
import CompanyTwitterSearch from '../components/CompanyTwitterSearch';
import TranscriptDisplay from '../components/TranscriptDisplay';
import CompanyAdminPanel from '../components/CompanyAdminPanel';
import EarningsHistory from '../components/EarningsHistory';
import CompetitiveDifferentiators from '../components/CompetitiveDifferentiators';
import ReactMarkdown from 'react-markdown';
import CompanyNews from '../components/CompanyNews';
import '../styles/CompanyPageNew.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

const CompanyPageNew = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [companyData, setCompanyData] = useState({});
    const [onWatchlist, setOnWatchlist] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { userId, token, serverAddress, flaskServerAddress } = useAuth();
    const [filings, setFilings] = useState(null);
    const [products, setProducts] = useState([]);
    const [stockChartTimeRange, setStockChartTimeRange] = useState('3y');
    const [focusedItem, setFocusedItem] = useState(null);
    const sidebarRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    const isAdmin = userId === 'ce690a5b-f97d-448c-aa73-b8677f476c86' || 
                    userId === '7e532348-5702-4cd2-9524-cae6b8f31ae8';

    // Get the active section from URL or default to 'overview'
    const getActiveSectionFromUrl = () => {
        const params = new URLSearchParams(location.search);
        const section = params.get('section') || 'overview';
        console.log('Current section from URL:', section);
        return section;
    };

    const [activeSection, setActiveSection] = useState(getActiveSectionFromUrl());

    // Update URL when section changes
    const handleSectionChange = (sectionId) => {
        console.log('Changing section to:', sectionId);
        setActiveSection(sectionId);
        setFocusedItem(sectionId);
        navigate(`/company/${id}?section=${sectionId}`, { replace: true });
        // Reset scroll position when changing sections
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Update active section when URL changes
    useEffect(() => {
        setActiveSection(getActiveSectionFromUrl());
    }, [location.search]);

    // Updated sidebar navigation items
    const sidebarSections = [
        { 
            id: 'overview', 
            label: 'Company Overview',
            subsections: [
                { id: 'products', label: 'Products' },
                { id: 'financials', label: 'Financials' },
                { id: 'earnings', label: 'Earnings History' },
                { id: 'filings', label: 'Company Filings' },
                { id: 'transcripts', label: 'Earnings Transcripts' }
            ]
        },
        { 
            id: 'behavior', 
            label: 'Customer Behavior', 
            subsections: [
                { id: 'nps', label: 'Estimated NPS' },
                { id: 'netadds', label: 'Net Adds / Losses' },
                { id: 'pricing', label: 'Pricing & WTP' }
            ]
        },
        { 
            id: 'competitive', 
            label: 'Competitive Landscape', 
            subsections: [
                { id: 'competitive-overview', label: 'Competitive Overview' },
                { id: 'head-to-head', label: 'Head-to-Head' },
                { id: 'comp-losses', label: 'Competitive Losses / Wins' }
            ]
        },
        { 
            id: 'trends', 
            label: 'Recent Trends', 
            subsections: [
                { id: 'search', label: 'Search All Topics' },
                { id: 'news', label: 'News' },
                { id: 'twitter', label: 'Twitter / X' },
                { id: 'threat-radar', label: 'Threat Radar' }
            ]
        },
        { 
            id: 'management', 
            label: 'Management', 
            subsections: [
                { id: 'profiles', label: 'Profiles' },
                { id: 'track-record', label: 'Track Record' }
            ]
        },
    ];

    const fetchCompanyData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(serverAddress + 'company', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    id: id,
                    user_id: userId,
                    token: token,
                }),
            });
            const data = await response.json();
            setCompanyData(data.data);
            console.log('Company data:', data.data);
            setOnWatchlist(data.data.is_watchlisted || false);
        } catch (error) {
            console.error('Error fetching company data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCompanyData();
    }, [userId, token, serverAddress, id]);

    useEffect(() => {
        if (companyData.name) {
            document.title = companyData.name;
        }
    }, [companyData.name]);

    const handleWatchlist = async () => {
        const newWatchlistStatus = !onWatchlist;
        setOnWatchlist(newWatchlistStatus);

        await fetch(serverAddress + 'toggle-watchlist', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                company_id: id,
                user_id: userId,
                on_watchlist: !newWatchlistStatus,
                token: token,
            }),
        });
    };

    const hasTopicData = (posts, requiredAssessmentType) => {
        if (!posts || !Array.isArray(posts) || posts.length === 0) {
            return false;
        }

        // Special handling for competitor churn data
        if (requiredAssessmentType === 'competitor_churn') {
            return posts.some(post => {
                if (!post || !Array.isArray(post.post_assessments)) {
                    return false;
                }
                const assessment = post.post_assessments.find(a => a?.type === 'leaver_joiner');
                return assessment?.details?.competitor && assessment?.rating !== null;
            });
        }

        // Regular handling for other types
        return posts.some(post => {
            if (!post || !Array.isArray(post.post_assessments)) {
                return false;
            }
            return post.post_assessments.some(assessment => 
                assessment?.type === requiredAssessmentType && assessment?.rating !== null
            );
        });
    };

    const handleStockChartTimeRangeChange = (newRange) => {
        setStockChartTimeRange(newRange);
    };

    const getRelevantPosts = () => {
        if (!companyData?.topics) return [];
        
        const allPosts = companyData.topics.flatMap(topic => topic.posts || []);
        
        // First try to get posts with high relevance
        const relevantPosts = allPosts.filter(post => 
            post?.post_assessments?.some(assessment => 
                assessment?.type === 'relevance' && assessment?.rating >= 6
            )
        );

        // If no relevant posts found, return all posts
        const postsToShow = relevantPosts.length > 0 ? relevantPosts : allPosts;
        
        // Sort by relevance rating first (if available), then by time
        return postsToShow.sort((a, b) => {
            const aRelevance = a?.post_assessments?.find(assessment => assessment?.type === 'relevance')?.rating || 0;
            const bRelevance = b?.post_assessments?.find(assessment => assessment?.type === 'relevance')?.rating || 0;
            
            if (bRelevance !== aRelevance) {
                return bRelevance - aRelevance;
            }
            
            return (b?.time || 0) - (a?.time || 0);
        }).slice(0, 10); // Limit to 10 posts
    };

    const MIN_NEWS_RELEVANCE = 5;

    const renderMainContent = () => {
        console.log('Rendering section:', activeSection);
        const allPosts = companyData.topics?.flatMap(topic => topic.posts) || [];

        const getRelevantNews = () => {
            if (!Array.isArray(allPosts)) return [];
            
            return allPosts
                .filter(post => 
                    post?.post_assessments?.some(assessment => 
                        assessment?.type === 'relevance' && 
                        assessment?.rating >= MIN_NEWS_RELEVANCE
                    )
                )
                .sort((a, b) => (b?.time || 0) - (a?.time || 0));
        };

        switch (activeSection) {
            case 'overview':
            // case 'financials':
            // case 'filings':
                return (
                    <div className="company-overview">
                        <div className="overview-content">
                            {activeSection === 'overview' && (
                                <>
                                    <div className="overview-grid">
                                        <div className="overview-left">
                                            <ContentCard title="Company Overview">
                                                {companyData.description ? (
                                                    <p>{companyData.description}</p>
                                                ) : (
                                                    <NoDataMessage 
                                                        topic="company overview"
                                                        companyName={companyData.name}
                                                        companyId={id}
                                                        companyTicker={companyData.ticker}
                                                        userId={userId}
                                                        dataType="COMPANY_OVERVIEW"
                                                    />
                                                )}
                                            </ContentCard>
                                            {companyData.ticker && (
                                                <ContentCard>
                                                    <div className="stock-chart-header">
                                                        <TimeRangeSelector
                                                            timeRange={stockChartTimeRange}
                                                            onTimeRangeChange={handleStockChartTimeRangeChange}
                                                            periods={['1y', '3y', '5y', '10y', 'all']}
                                                            defaultPeriod="3y"
                                                        />
                                                    </div>
                                                    <StockChartContainer 
                                                        symbol={companyData.ticker} 
                                                        timePeriod={stockChartTimeRange} 
                                                    />
                                                </ContentCard>
                                            )}
                                        </div>
                                        <div className="overview-right">
                                            <ContentCard 
                                                title={
                                                    <div className="card-header-with-action">
                                                        <span>Recent Scuttlebutt</span>
                                                        <button 
                                                            className="view-all-button"
                                                            onClick={() => handleSectionChange('news')}
                                                        >
                                                            View All <ArrowForwardIcon />
                                                        </button>
                                                    </div>
                                                }
                                                variant="headerOnly"
                                            >
                                                <div className="scuttlebutt-content-wrapper">
                                                    {getRelevantPosts().length > 0 ? (
                                                        <PostDisplay 
                                                            data={getRelevantPosts()}
                                                            header=""
                                                            showBookmarkButton={false}
                                                            noResults={getRelevantPosts().length === 0}
                                                            initialPostCount={10}
                                                            onLinkClick={() => handleSectionChange('news')}
                                                            linkText="View More Scuttlebutt"
                                                        />
                                                    ) : (
                                                        <NoDataMessage 
                                                            topic="recent scuttlebutt"
                                                            companyName={companyData.name}
                                                            companyId={id}
                                                            companyTicker={companyData.ticker}
                                                            userId={userId}
                                                            dataType="SCUTTLEBUTT"
                                                        />
                                                    )}
                                                </div>
                                            </ContentCard>
                                        </div>
                                    </div>
                                </>
                            )}
                            {/* {activeSection === 'financials' && (
                                <ContentCard title="Financial Performance" variant="headerOnly">
                                    {companyData.ticker ? (
                                        <FundamentalsChart symbol={companyData.ticker} />
                                    ) : (
                                        <NoDataMessage 
                                            topic="financial data"
                                            companyName={companyData.name}
                                            companyId={id}
                                            companyTicker={companyData.ticker}
                                            userId={userId}
                                            dataType="FINANCIAL_DATA"
                                        />
                                    )}
                                </ContentCard>
                            )} */}
                            {/* {activeSection === 'filings' && (
                                <div>
                                    {filings ? (
                                        <FilingsTable filings={filings} companyData={companyData} />
                                    ) : (
                                        <NoDataMessage 
                                            topic="company filings"
                                            companyName={companyData.name}
                                            companyId={id}
                                            companyTicker={companyData.ticker}
                                            userId={userId}
                                            dataType="SEC_FILINGS"
                                        />
                                    )}
                                </div>
                            )} */}
                        </div>
                    </div>
                );
            case 'netadds':
                return (
                    <ContentCard title="Net Adds and Losses">
                        {hasTopicData(allPosts, 'leaver_joiner') ? (
                            <LeaverJoinerPlot 
                                posts={allPosts}
                                stat_name="leaver_joiner"
                                titles={["Customer Churn", "Competitor Churn", "Competitive Analysis"]}
                                product_name={companyData.name}
                            />
                        ) : (
                            <NoDataMessage 
                                topic="customer churn"
                                companyName={companyData.name}
                                companyId={id}
                                companyTicker={companyData.ticker}
                                userId={userId}
                                dataType="CUSTOMER_CHURN"
                            />
                        )}
                    </ContentCard>
                );
            case 'nps':
                return (
                    <ContentCard title="Estimated Net Promoter Score">
                        {hasTopicData(allPosts, 'NPS') ? (
                            <NPSPlot 
                                posts={allPosts}
                                stat_name="NPS"
                                title="Net Promoter Score"
                                topics={companyData.topics?.filter(topic => 
                                    topic.posts?.some(post => 
                                        post.post_assessments?.some(assessment => 
                                            assessment.type === 'NPS'
                                        )
                                    )
                                )}
                            />
                        ) : (
                            <NoDataMessage 
                                topic="NPS data"
                                companyName={companyData.name}
                                companyId={id}
                                companyTicker={companyData.ticker}
                                userId={userId}
                                dataType="NPS_DATA"
                            />
                        )}
                    </ContentCard>
                );
            case 'comp-losses':
                return (
                    <ContentCard title="Competitive Losses / Wins">
                        {hasTopicData(allPosts, 'competitor_churn') ? (
                            <CompetitorChurnPlot 
                                posts={allPosts}
                                product_name={companyData.name}
                            />
                        ) : (
                            <NoDataMessage 
                                topic="competitive loss data"
                                companyName={companyData.name}
                                companyId={id}
                                companyTicker={companyData.ticker}
                                userId={userId}
                                dataType="COMPETITIVE_LOSSES"
                            />
                        )}
                    </ContentCard>
                );
            case 'head-to-head':
                return (
                    <ContentCard title="Head-to-Head Comparisons">
                        {hasTopicData(allPosts, 'competitive_comparison') ? (
                            <CompetitiveComparisonPlot 
                                posts={allPosts}
                                product_name={companyData.name}
                            />
                        ) : (
                            <NoDataMessage 
                                topic="head-to-head comparison data"
                                companyName={companyData.name}
                                companyId={id}
                                companyTicker={companyData.ticker}
                                userId={userId}
                                dataType="COMPETITIVE_COMPARISON"
                            />
                        )}
                    </ContentCard>
                );
            case 'competitive-overview':
                return (
                    <CompetitiveDifferentiators 
                        topics={companyData.topics}
                        allPosts={allPosts}
                        hasTopicData={hasTopicData}
                        companyName={companyData.name}
                        companyId={id}
                        companyTicker={companyData.ticker}
                        userId={userId}
                    />
                );
            case 'pricing':
                return (
                    <ContentCard title="Pricing & Willingness to Pay">
                        {hasTopicData(allPosts, 'pricing') ? (
                            <PricingPlot 
                                posts={allPosts}
                                title="Price Sensitivity Analysis"
                            />
                        ) : (
                            <NoDataMessage 
                                topic="pricing data"
                                companyName={companyData.name}
                                companyId={id}
                                companyTicker={companyData.ticker}
                                userId={userId}
                                dataType="PRICING_DATA"
                            />
                        )}
                    </ContentCard>
                );
            // case 'products':
            //     return (
            //         <ContentCard title="Products">
            //             {products.length > 0 ? (
            //                 <div className="products-section">
            //                     {products.map((product, index) => (
            //                         <div key={index} className="card card-md product-card">
            //                             <div className="product-header">
            //                                 <h3 className="product-name">{product.topic}</h3>
            //                             </div>
            //                             {product.additional_text && (
            //                                 <div className="product-description">
            //                                     <p>{product.additional_text}</p>
            //                                 </div>
            //                             )}
            //                         </div>
            //                     ))}
            //                 </div>
            //             ) : (
            //                 <NoDataMessage 
            //                     topic="product information"
            //                     companyName={companyData.name}
            //                     companyId={id}
            //                     companyTicker={companyData.ticker}
            //                     userId={userId}
            //                     dataType="PRODUCT_INFO"
            //                 />
            //             )}
            //         </ContentCard>
            //     );
            case 'twitter':
                return (
                    <CompanyTwitterSearch 
                        companyName={companyData.name}
                        ticker={companyData.ticker}
                    />
                );
            // case 'transcripts':
            //     return (
            //         <ContentCard title="Earnings Call Transcripts">
            //             <TranscriptDisplay 
            //                 companyData={companyData}
            //                 filings={filings}
            //             />
            //         </ContentCard>
            //     );
            // case 'earnings':
            //     return (
            //         <ContentCard title="Earnings History">
            //             {companyData.ticker ? (
            //                 <EarningsHistory 
            //                     ticker={companyData.ticker}
            //                     flaskServerAddress={flaskServerAddress}
            //                     companyData={companyData}
            //                     filings={filings}
            //                 />
            //             ) : (
            //                 <NoDataMessage 
            //                     topic="earnings data"
            //                     companyName={companyData.name}
            //                     companyId={id}
            //                     companyTicker={companyData.ticker}
            //                     userId={userId}
            //                     dataType="EARNINGS_DATA"
            //                 />
            //             )}
            //         </ContentCard>
            //     );
            case 'news':
                return (
                    <ContentCard title="Recent Scuttlebutt" variant="headerOnly">
                        <CompanyNews 
                            allPosts={allPosts}
                            companyName={companyData.name}
                            companyId={id}
                            companyTicker={companyData.ticker}
                            userId={userId}
                        />
                    </ContentCard>
                );
            case 'search':
                return (
                    <ContentCard title="Search Scuttlebutt" variant="headerOnly">
                        <div className="search-container">
                            <TextField
                                fullWidth
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                placeholder="Search across all topics..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: searchQuery && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setSearchQuery('');
                                                    setSearchResults([]);
                                                }}
                                                edge="end"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <div className="search-button-container">
                                <button 
                                    className="search-button"
                                    onClick={handleSearch}
                                    disabled={!searchQuery.trim() || isSearching}
                                >
                                    {isSearching ? <CircularProgress size={24} /> : 'Search'}
                                </button>
                            </div>
                            
                            <div className="search-results">
                                {searchResults.length > 0 ? (
                                    <PostDisplay
                                        data={searchResults}
                                        showBookmarkButton={true}
                                        noResults={false}
                                        query={searchQuery}
                                    />
                                ) : searchQuery && !isSearching && (
                                    <div className="no-results-message">
                                        No results found for "{searchQuery}"
                                    </div>
                                )}
                            </div>
                        </div>
                    </ContentCard>
                );
            default:
                return (
                    <ContentCard title="Section Under Development">
                        <div>This section is currently under development.</div>
                    </ContentCard>
                );
        }
    };

    // Handle main section click
    const handleMainSectionClick = (sectionId) => {
        if (sectionId === 'overview') {
            setFocusedItem(sectionId);
            handleSectionChange('overview');
        }
    };

    useEffect(() => {
        const fetchFilings = async () => {
            try {
                console.log('Attempting to fetch filings with CIK:', companyData.cik);
                const response = await fetch(flaskServerAddress + `filings/${companyData.cik}/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });
                const data = await response.json();
                console.log('Raw filings data from server:', data);
                console.log('Sample filing from 8-K:', data.categories?.['8-K']?.filings?.[0]);
                setFilings(data);
            } catch (error) {
                console.error('Error fetching filings:', error);
            }
        };
        
        if (companyData.cik && userId && token) {
            console.log('Conditions met for fetching filings');
            fetchFilings();
        }
    }, [companyData.cik, userId, token, flaskServerAddress]);

    useEffect(() => {
        if (companyData.topics) {
            const prod = companyData.topics
                ?.filter(topic => topic.relationship === "product")
                .sort((a, b) => {
                    // Handle undefined priorities - put them at the end
                    if (a.priority === undefined && b.priority === undefined) return 0;
                    if (a.priority === undefined) return 1;
                    if (b.priority === undefined) return -1;
                    return a.priority - b.priority;
                }) || [];
            console.log('Products:', prod);
            setProducts(prod);
        }
    }, [companyData]);

    const getAllNavigableItems = useCallback(() => {
        const flattenedItems = sidebarSections.reduce((acc, section) => {
            if (section.id === 'overview') {
                acc.push({ id: section.id, label: section.label });
            }
            section.subsections?.forEach(subsection => {
                acc.push({ id: subsection.id, label: subsection.label });
            });
            return acc;
        }, []);
        return flattenedItems;
    }, []);

    const handleKeyDown = useCallback((e) => {
        if (!focusedItem) return;

        const items = getAllNavigableItems();
        const currentIndex = items.findIndex(item => item.id === focusedItem);

        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                if (currentIndex > 0) {
                    const prevItem = items[currentIndex - 1];
                    setFocusedItem(prevItem.id);
                    handleSectionChange(prevItem.id);
                }
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (currentIndex < items.length - 1) {
                    const nextItem = items[currentIndex + 1];
                    setFocusedItem(nextItem.id);
                    handleSectionChange(nextItem.id);
                }
                break;
            default:
                break;
        }
    }, [focusedItem, getAllNavigableItems]);

    useEffect(() => {
        const sidebarElement = sidebarRef.current;
        if (sidebarElement) {
            sidebarElement.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (sidebarElement) {
                sidebarElement.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [handleKeyDown]);

    const handleSearch = async () => {
        if (!searchQuery.trim() || !companyData.topics) return;
        
        setIsSearching(true);
        try {
            // Get all topic IDs
            const topicIds = companyData.topics.map(topic => topic.id).join(',');
            
            // Encode the search query
            const encodedQuery = encodeURIComponent(searchQuery);
            
            // Set the minimum relevance to 4
            const minRelevance = 4;
            
            // Make the search request
            const response = await fetch(
                `${flaskServerAddress}topic-relevance-search/?topic_ids=${topicIds}&query=${encodedQuery}&min_relevance=${minRelevance}&limit=100`
            );
            
            if (!response.ok) {
                throw new Error('Search request failed');
            }
            
            const data = await response.json();
            setSearchResults(data);
        } catch (error) {
            console.error('Error performing search:', error);
            setSearchResults([]);
        } finally {
            setIsSearching(false);
        }
    };

    if (isLoading) return <div className="progress-indicator"><CircularProgress /></div>;

    return (
        <div className="company-page-new">
            <div className="navbar-extension" />
            <div className="company-header">
                <div className="company-info">
                    {companyData.logo && companyData.website && (
                        <a 
                            href={companyData.website} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="company-logo-link"
                        >
                            <img 
                                src={companyData.logo} 
                                alt={`${companyData.name} logo`} 
                                className="company-logo"
                            />
                        </a>
                    )}
                    <div className="company-text-content">
                        <h1>{companyData.name}</h1>
                        <div className="company-metadata">
                            {companyData.ticker && <span>Ticker: {companyData.ticker}</span>}
                            {companyData.cik && <span>CIK: {companyData.cik}</span>}
                            {companyData.exchange && <span>Exchange: {companyData.exchange}</span>}
                            {companyData.sector && <span>Sector: {companyData.sector}</span>}
                        </div>
                    </div>
                </div>
                <div className="header-actions">
                    <CompanyAdminPanel
                        companyId={id}
                        companyData={companyData}
                        flaskServerAddress={flaskServerAddress}
                        onTopicCreated={fetchCompanyData}
                        isAdmin={isAdmin}
                    />
                    <button 
                        className={`watchlist-button ${onWatchlist ? 'on-watchlist' : ''}`}
                        onClick={handleWatchlist}
                    >
                        <VisibilityIcon />
                        {onWatchlist ? 'On Watchlist' : 'Add to Watchlist'}
                    </button>
                </div>
            </div>            
            <div className="company-content">
                <nav 
                    className="company-sidebar" 
                    ref={sidebarRef}
                    tabIndex="0"
                >
                    {sidebarSections.map(section => (
                        <div key={section.id} className="sidebar-section">
                            <div 
                                className={`sidebar-item main-section ${
                                    section.id === 'overview' ? 'clickable' : ''
                                } ${activeSection === 'overview' && section.id === 'overview' ? 'active' : ''} ${
                                    focusedItem === section.id ? 'focused' : ''
                                }`}
                                onClick={() => handleMainSectionClick(section.id)}
                                tabIndex="0"
                            >
                                {section.label}
                            </div>
                            <div className="sidebar-subsections">
                                {section.subsections && section.subsections.map(subsection => (
                                    <div 
                                        key={subsection.id}
                                        className={`sidebar-item ${activeSection === subsection.id ? 'active' : ''} ${
                                            focusedItem === subsection.id ? 'focused' : ''
                                        }`}
                                        onClick={() => handleSectionChange(subsection.id)}
                                        tabIndex="0"
                                    >
                                        {subsection.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </nav>
                
                <main className="company-main-content">
                    {renderMainContent()}
                </main>
            </div>
        </div>
    );
};

export default CompanyPageNew; 