import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import debounce from 'lodash/debounce';
import supabase from './supabaseClient';
import SearchPreviewResultCompany from './SearchPreviewResultCompany';
import '../styles/SearchPreview.css';

const SearchPreviewNew = ({
  query,
  parentRef,
  anchorRef,
  onlyShowCompanySearch = false,
  handleSearch,
  handleClick,
  previewTextOverride,
  setCompany,
  onClear
}) => {
  const [results, setResults] = useState([]);
  const [position, setPosition] = useState({ x: 0, y: 0, width: 0 });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const previewRef = useRef(null);
  const navigate = useNavigate();
  
  // Add new state for controlling animation
  const [isVisible, setIsVisible] = useState(false);

  // Create a debounced search function
  const debouncedSearch = useRef(
    debounce(async (searchTerm) => {
      if (!searchTerm || searchTerm.length < 2) {
        setResults([]);
        return;
      }

      try {
        const { data, error } = await supabase
          .rpc('search_companies', { search_term: searchTerm });

        if (error) throw error;
        setResults(data || []);
      } catch (error) {
        console.error('Search error:', error);
        setResults([]);
      }
    }, 300)
  ).current;

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Update search when query changes
  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  // Position the preview dropdown
  useEffect(() => {
    const referenceElement = anchorRef?.current || parentRef.current;
    if (!referenceElement) return;

    const updatePosition = () => {
      const rect = referenceElement.getBoundingClientRect();
      setPosition({
        x: rect.left,
        y: rect.bottom + window.scrollY,
        width: rect.width
      });
    };

    const resizeObserver = new ResizeObserver(updatePosition);
    resizeObserver.observe(referenceElement);
    updatePosition();

    return () => resizeObserver.disconnect();
  }, [parentRef, anchorRef]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (['ArrowDown', 'ArrowUp', 'Enter'].includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
        
        if (!results.length) return;

        switch (e.key) {
          case 'ArrowDown':
            setSelectedIndex(prev => 
              prev < (onlyShowCompanySearch ? results.length - 1 : results.length) 
                ? prev + 1 
                : 0
            );
            break;
          case 'ArrowUp':
            setSelectedIndex(prev => 
              prev > 0 
                ? prev - 1 
                : (onlyShowCompanySearch ? results.length - 1 : results.length)
            );
            break;
          case 'Enter':
            if (selectedIndex === 0 && !onlyShowCompanySearch) {
              // Start navigation immediately
              handleSearch?.(query);
              
              // Handle UI cleanup
              setIsVisible(false);
              setIsActive(false);
              
              // Delay blur until after animation
              setTimeout(() => {
                parentRef.current?.blur();
              }, 200);
            } else {
              const company = results[onlyShowCompanySearch ? selectedIndex : selectedIndex - 1];
              if (company) {
                handleItemClick(company);
              }
            }
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);
    return () => document.removeEventListener('keydown', handleKeyDown, true);
  }, [results, selectedIndex, onlyShowCompanySearch, handleSearch, query]);

  const handleItemClick = async (company) => {
    // Start navigation immediately
    if (handleClick) {
      handleClick(company);
    } else if (setCompany) {
      setCompany(company);
    } else {
      navigate(`/company/${company.id}`);
    }
    
    // Handle UI cleanup in parallel
    setIsVisible(false);
    onClear?.();
    
    // Wait for animation before blur
    setTimeout(() => {
      parentRef.current?.blur();
    }, 200);
  };

  // Add focus handlers
  useEffect(() => {
    const handleFocus = () => {
      setIsActive(true);
      // Small delay to ensure smooth animation
      setTimeout(() => setIsVisible(true), 50);
    };

    const handleClickOutside = (event) => {
      const referenceElement = anchorRef?.current || parentRef.current;
      if (previewRef.current && 
          !previewRef.current.contains(event.target) && 
          !referenceElement.contains(event.target)) {
        setIsVisible(false);
        // Wait for animation to complete before hiding
        setTimeout(() => setIsActive(false), 200);
      }
    };

    const input = parentRef.current;
    input?.addEventListener('focus', handleFocus);
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      input?.removeEventListener('focus', handleFocus);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [parentRef, anchorRef]);

  // Update scroll handler
  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(false);
      // Wait for animation to complete before hiding
      setTimeout(() => setIsActive(false), 200);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Modify the renderPreview function
  const renderPreview = () => {
    if (!results.length || !isActive) return null;

    const previewContent = (
      <div
        ref={previewRef}
        className={`search-preview-container ${isVisible ? 'visible' : ''}`}
        style={{
          position: 'fixed',
          top: position.y,
          left: position.x,
          width: position.width,
        }}
      >
        {!onlyShowCompanySearch && (
          <div
            className={`search-preview-item ${selectedIndex === 0 ? 'selected' : ''}`}
            onClick={() => handleSearch?.(query)}
          >
            {previewTextOverride || `Search for Scuttlebutt about "${query}"`}
          </div>
        )}
        
        {results.map((company, index) => {
          const itemIndex = onlyShowCompanySearch ? index : index + 1;
          return (
            <SearchPreviewResultCompany 
              key={company.id}
              company={company}
              isSelected={itemIndex === selectedIndex}
              onClick={handleItemClick}
            />
          );
        })}
      </div>
    );

    return createPortal(previewContent, document.body);
  };

  return renderPreview();
};

export default SearchPreviewNew; 