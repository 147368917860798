import React, { useState, useEffect, useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import PostDisplay from './PostDisplay';
import CloseIcon from '@mui/icons-material/Close';
import TimeRangeSelector from './TimeRangeSelector';
import { subYears } from 'date-fns';
import '../styles/PricingPlot.css';
import FilteredPostsDisplay from './FilteredPostsDisplay';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const colors = {
    veryReceptive: '#2ecc71',      // Bright green
    moderatelyReceptive: '#82e0aa', // Light green
    neutral: '#d5d8dc',            // Light gray
    notReceptive: '#f1948a',       // Light red
    extremelyNotReceptive: '#e74c3c' // Bright red
};

const PricingSummary = ({ categoryCounts, onCategoryClick }) => {
    const total = Object.values(categoryCounts).reduce((a, b) => a + b, 0);
    const receptive = categoryCounts.veryReceptive + categoryCounts.moderatelyReceptive;
    const resistant = categoryCounts.extremelyNotReceptive + categoryCounts.notReceptive;
    
    return (
        <div className="pricing-summary">
            <div 
                className="summary-box"
                style={{ borderLeft: `4px solid ${colors.veryReceptive}` }}
                onClick={() => onCategoryClick(['veryReceptive', 'moderatelyReceptive'])}
            >
                <h2 style={{ color: colors.veryReceptive }}>
                    {((receptive / total) * 100).toFixed(1)}%
                </h2>
                <p>Open to Price Increase</p>
            </div>
            <div 
                className="summary-box"
                style={{ borderLeft: `4px solid ${colors.neutral}` }}
                onClick={() => onCategoryClick(['neutral'])}
            >
                <h2 style={{ color: '#666' }}>
                    {((categoryCounts.neutral / total) * 100).toFixed(1)}%
                </h2>
                <p>Indifferent</p>
            </div>
            <div 
                className="summary-box"
                style={{ borderLeft: `4px solid ${colors.extremelyNotReceptive}` }}
                onClick={() => onCategoryClick(['notReceptive', 'extremelyNotReceptive'])}
            >
                <h2 style={{ color: colors.extremelyNotReceptive }}>
                    {((resistant / total) * 100).toFixed(1)}%
                </h2>
                <p>Price Sensitive</p>
            </div>
        </div>
    );
};

const PricingPlot = ({ posts, title }) => {
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [postsReferenced, setPostsReferenced] = useState([]);
    const [demandData, setDemandData] = useState(null);
    const [timeRange, setTimeRange] = useState('3y');

    const thresholds = {
        veryReceptive: 7,
        moderatelyReceptive: 3,
        notReceptive: -3,
        extremelyNotReceptive: -7
    };

    const willingness = {
        veryReceptive: 1,
        moderatelyReceptive: 0.95,
        neutral: 0.65,
        notReceptive: 0.35,
        extremelyNotReceptive: 0.10
    };

    const pricePoints = [0, 0.05, 0.1, 0.15, 0.2]; // 0% to 20% price increase

    const fetchData = () => {
        const now = new Date();
        
        const filteredPosts = (posts || []).filter(post => {
            if (!post?.time) return false;
            if (timeRange === 'all') return true;
            const date = new Date(post.time * 1000);
            const years = parseInt(timeRange);
            return date >= subYears(now, years);
        });

        const pricingData = filteredPosts
            .map(post => {
                if (!post?.post_assessments) return null;
                const assessment = post.post_assessments.find(assessment => assessment?.type === 'pricing');
                if (assessment) {
                    return {
                        ...assessment,
                        time: post.time,
                        id: post.id
                    };
                }
                return null;
            })
            .filter(item => item !== null);
        console.log('pricingData', pricingData);
        setData(pricingData);
    };

    useEffect(() => {
        if (Array.isArray(posts) && posts.length > 0) {
            fetchData();
        }
    }, [posts, timeRange]);

    const chartDataMemo = useMemo(() => {
        if (data.length === 0) return null;

        const categoryCounts = {
            veryReceptive: 0,
            moderatelyReceptive: 0,
            neutral: 0,
            notReceptive: 0,
            extremelyNotReceptive: 0
        };

        data.forEach(item => {
            const rating = item.rating;
            if (rating !== null) {
                if (rating > thresholds.veryReceptive) {
                    categoryCounts.veryReceptive++;
                } else if (rating > thresholds.moderatelyReceptive) {
                    categoryCounts.moderatelyReceptive++;
                } else if (rating > thresholds.notReceptive) {
                    categoryCounts.neutral++;
                } else if (rating > thresholds.extremelyNotReceptive) {
                    categoryCounts.notReceptive++;
                } else {
                    categoryCounts.extremelyNotReceptive++;
                }
            }
        });

        const total = Object.values(categoryCounts).reduce((a, b) => a + b, 0);

        // Calculate the maximum percentage on either side
        const maxPercentage = Math.max(
            (categoryCounts.veryReceptive + categoryCounts.moderatelyReceptive) / total * 100,
            (categoryCounts.extremelyNotReceptive + categoryCounts.notReceptive) / total * 100
        );
        
        // Round up to nearest 10%
        const axisLimit = Math.ceil(maxPercentage / 10) * 10;

        return {
            categoryCounts,
            axisLimit,
            chartData: {
                labels: [''],
                datasets: [
                    {
                        label: 'Very Receptive',
                        data: [-(categoryCounts.veryReceptive / total) * 100],
                        backgroundColor: colors.veryReceptive,
                        stack: 'left'
                    },
                    {
                        label: 'Moderately Receptive',
                        data: [-(categoryCounts.moderatelyReceptive / total) * 100],
                        backgroundColor: colors.moderatelyReceptive,
                        stack: 'left'
                    },
                    {
                        label: 'Extremely Not Receptive',
                        data: [+(categoryCounts.extremelyNotReceptive / total) * 100],
                        backgroundColor: colors.extremelyNotReceptive,
                        stack: 'right'
                    },
                    {
                        label: 'Not Receptive',
                        data: [+(categoryCounts.notReceptive / total) * 100],
                        backgroundColor: colors.notReceptive,
                        stack: 'right'
                    }
                ]
            }
        };
    }, [data]);

    // const demandDataMemo = useMemo(() => {
    //     if (!chartDataMemo) return null;
    
    //     const { categoryCounts } = chartDataMemo;
    //     const totalPosts = Object.values(categoryCounts).reduce((sum, count) => sum + count, 0);
    
    //     const demandPoints = pricePoints.map(priceIncrease => {
    //         let remainingDemand = 0;
    //         for (const category in categoryCounts) {
    //             const categoryPercentage = categoryCounts[category] / totalPosts;
    //             const willingnessToPayAtPrice = Math.max(0, 1 - (priceIncrease / willingness[category]));
    //             remainingDemand += categoryPercentage * willingnessToPayAtPrice;
    //         }
    //         return remainingDemand * 100; // Convert to percentage
    //     });
    
    //     return {
    //         labels: pricePoints.map(p => `+${p * 100}%`),
    //         datasets: [
    //             {
    //                 label: 'Demand',
    //                 data: demandPoints,
    //                 borderColor: 'rgb(75, 192, 192)',
    //                 tension: 0.1
    //             }
    //         ]
    //     };
    // }, [chartDataMemo]);

    useEffect(() => {
        setChartData(chartDataMemo?.chartData || null);
    }, [chartDataMemo]);

    // useEffect(() => {
    //     setDemandData(demandDataMemo);
    // }, [demandDataMemo]);

    const handleBarClick = (event, elements) => {
        if (elements.length > 0) {
            const { datasetIndex } = elements[0];
            
            // Map datasetIndex to categories based on our updated chart order
            let selectedCategory;
            switch (datasetIndex) {
                case 0: // First bar (leftmost)
                    selectedCategory = 'veryReceptive';
                    break;
                case 1:
                    selectedCategory = 'moderatelyReceptive';
                    break;
                case 2:
                    selectedCategory = 'extremelyNotReceptive';
                    break;
                case 3:
                    selectedCategory = 'notReceptive';
                    break;
                default:
                    selectedCategory = null;
            }

            setSelectedCategory(selectedCategory);

            if (selectedCategory) {
                const categoryPosts = getCategoryPosts(selectedCategory);
                setPostsReferenced(categoryPosts);
            }
        } else {
            setSelectedCategory(null);
            setPostsReferenced([]);
        }
    };

    const getCategoryPosts = (category) => {
        if (!category) return [];

        return (posts || []).filter(post => {
            if (!post?.post_assessments) return false;
            const assessment = post.post_assessments.find(a => a?.type === 'pricing');
            if (!assessment || assessment.rating === null) return false;

            const rating = assessment.rating;
            switch (category) {
                case 'veryReceptive':
                    return rating > thresholds.veryReceptive;
                case 'moderatelyReceptive':
                    return rating > thresholds.moderatelyReceptive && rating <= thresholds.veryReceptive;
                case 'neutral':
                    return rating > thresholds.notReceptive && rating <= thresholds.moderatelyReceptive;
                case 'notReceptive':
                    return rating > thresholds.extremelyNotReceptive && rating <= thresholds.notReceptive;
                case 'extremelyNotReceptive':
                    return rating <= thresholds.extremelyNotReceptive;
                default:
                    return false;
            }
        }).map(post => {
            const assessment = post.post_assessments.find(a => a?.type === 'pricing');
            return {
                ...post,
                rating: assessment.rating,
                confidence: assessment.confidence,
                explanation: assessment.explanation,
                ratingName: 'pricing',
                ratingType: 'pricing'
            };
        });
    };

    const handleClosePostsReferenced = () => {
        setPostsReferenced([]);
        setSelectedCategory(null);
    };

    // Calculate weighted average score
    const calculatePriceSensitivityScore = (categoryCounts) => {
        const weights = {
            veryReceptive: 2,
            moderatelyReceptive: 1,
            neutral: 0,
            notReceptive: -1,
            extremelyNotReceptive: -2
        };
        
        let weightedSum = 0;
        let total = 0;
        
        Object.entries(categoryCounts).forEach(([category, count]) => {
            weightedSum += weights[category] * count;
            total += count;
        });
        
        // Normalize to 0-100 scale
        return ((weightedSum / total) + 2) * 25;
    };

    const handleSummaryClick = (categories) => {
        // Get posts for all selected categories
        const categoryPosts = posts.filter(post => {
            const assessment = post.post_assessments.find(a => a.type === 'pricing');
            if (!assessment || assessment.rating === null) return false;

            const rating = assessment.rating;
            return categories.some(category => {
                switch (category) {
                    case 'veryReceptive':
                        return rating > thresholds.veryReceptive;
                    case 'moderatelyReceptive':
                        return rating > thresholds.moderatelyReceptive && rating <= thresholds.veryReceptive;
                    case 'neutral':
                        return rating > thresholds.notReceptive && rating <= thresholds.moderatelyReceptive;
                    case 'notReceptive':
                        return rating > thresholds.extremelyNotReceptive && rating <= thresholds.notReceptive;
                    case 'extremelyNotReceptive':
                        return rating <= thresholds.extremelyNotReceptive;
                    default:
                        return false;
                }
            });
        }).map(post => ({
            ...post,
            rating: post.post_assessments.find(a => a.type === 'pricing').rating,
            confidence: post.post_assessments.find(a => a.type === 'pricing').confidence,
            explanation: post.post_assessments.find(a => a.type === 'pricing').explanation,
            ratingName: 'pricing',
            ratingType: 'pricing'
        }));

        setPostsReferenced(categoryPosts);
        setSelectedCategory(categories.join('-')); // Join categories for display

        // Scroll to posts section
        setTimeout(() => {
            const postsSection = document.getElementById('pricing-posts');
            if (postsSection) {
                postsSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100);
    };

    return (
        <div className="pricing-plot">
            {chartData && (
                <>
                    {/* <div className="header">
                        <h3 className="title">{title}</h3>
                        <p className="subtitle">Analysis of customer sentiment towards potential price changes</p>
                    </div> */}
                    <PricingSummary 
                        categoryCounts={chartDataMemo.categoryCounts} 
                        onCategoryClick={handleSummaryClick}
                    />
                    <div className="chart-container">
                        <div className="chart-inner">
                            <Bar
                                data={chartData}
                                options={{
                                    indexAxis: 'y',
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    layout: {
                                        padding: {
                                            left: 10,
                                            right: 10
                                        }
                                    },
                                    scales: {
                                        x: { 
                                            stacked: true,
                                            title: { 
                                                display: true, 
                                                text: 'Distribution of Customer Sentiment (%)',
                                                font: {
                                                    size: 13,
                                                    weight: 500
                                                },
                                                padding: { top: 10 }
                                            },
                                            grid: {
                                                color: (context) => context.tick.value === 0 ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.1)',
                                                lineWidth: (context) => context.tick.value === 0 ? 2 : 1,
                                            },
                                            ticks: {
                                                callback: (value) => `${Math.abs(value)}%`
                                            },
                                            min: -chartDataMemo.axisLimit,
                                            max: chartDataMemo.axisLimit,
                                        },
                                        y: { 
                                            stacked: true,
                                            grid: {
                                                display: false
                                            },
                                            display: false  // Hide y-axis since we only have one bar
                                        },
                                    },
                                    plugins: {
                                        tooltip: {
                                            callbacks: {
                                                label: function(context) {
                                                    const value = Math.abs(context.parsed.x);
                                                    return `${context.dataset.label}: ${value.toFixed(1)}% of responses`;
                                                }
                                            }
                                        },
                                        legend: {
                                            position: 'bottom',
                                            labels: {
                                                padding: 20,
                                                font: {
                                                    size: 12
                                                },
                                                filter: (legendItem, data) => {
                                                    if (legendItem.text === 'Neutral') {
                                                        return data.datasets.findIndex(d => 
                                                            d.backgroundColor === colors.neutral
                                                        ) === legendItem.datasetIndex;
                                                    }
                                                    return true;
                                                }
                                            }
                                        }
                                    },
                                    onClick: handleBarClick,
                                    barThickness: 60,
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
            {/* {demandData && (
                <>
                    <h4 style={{textAlign: 'left'}}>Estimated Demand Curve</h4>
                    <p style={{textAlign: 'left'}}>Estimated Quantity Demanded as a Function of % Price Increase</p>
                    <Line
                        data={demandData}
                        options={{
                            responsive: true,
                            scales: {
                                x: { 
                                    title: { display: true, text: 'Price Increase' }
                                },
                                y: { 
                                    title: { display: true, text: 'Demand (%)' },
                                    min: 0,
                                    max: 100
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            return `Demand: ${context.parsed.y.toFixed(2)}%`;
                                        }
                                    }
                                }
                            }
                        }}
                    />
                </>
            )} */}
            <FilteredPostsDisplay 
                posts={postsReferenced}
                onClose={handleClosePostsReferenced}
                id="pricing-posts"
                title={'Posts Referenced'}
                subtitle={selectedCategory ? selectedCategory.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()) : ''}
                ratingDisplayName="Pricing Receptiveness"
                ratingColorScheme={{
                    low: 0,  // ratings <= 0 will be red
                    high: 4  // ratings > 4 will be green, ratings between 0-4 will be yellow
                }}
            />
        </div>
    );
};

export default PricingPlot;