import React from 'react';
import { useNavigate } from 'react-router-dom';

function VideoThumbnail({ video }) {
  const navigate = useNavigate();

  // Don't render if relevance is too low
  if (!video.relevance || video.relevance < 5) {
    return null;
  }

  const handleClick = () => {
    navigate(`/video/${video.id}`);
  };

  return (
    <div className="video-thumbnail" onClick={handleClick}>
      <img src={video.thumbnail_url} alt={video.title} />
      <h3>{video.title}</h3>
    </div>
  );
}

export default VideoThumbnail;