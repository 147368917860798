import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

export const tokens = () => ({
    dkblue: {
        100: "#cccef2",
        200: "#999de4",
        300: "#666bd7",
        400: "#333ac9",
        500: "#1042c1",
        600: "#000796",
        700: "#000571",
        800: "#00044b",
        900: "#000226"
    },
    white: {
        100: "#fefefe",
        200: "#fefefe",
        300: "#fdfdfd",
        400: "#fdfdfd",
        500: "#fcfcfc",
        600: "#cacaca",
        700: "#979797",
        800: "#656565",
        900: "#323232"
    },
    offwhite: {
        100: "#fbfbfb",
        200: "#f8f8f8",
        300: "#f4f4f4",
        400: "#f1f1f1",
        500: "#ededed",
        600: "#bebebe",
        700: "#8e8e8e",
        800: "#5f5f5f",
        900: "#2f2f2f"
    },
    vistablue: {
        100: "#e6e9fe",
        200: "#cdd3fd",
        300: "#b5bcfc",
        400: "#9ca6fb",
        500: "#8390fa",
        600: "#6973c8",
        700: "#4f5696",
        800: "#343a64",
        900: "#1a1d32"
    },
    dimgray: {
        100: "#dfdfe0",
        200: "#c0c0c1",
        300: "#a0a0a1",
        400: "#818182",
        500: "#616163",
        600: "#4e4e4f",
        700: "#3a3a3b",
        800: "#272728",
        900: "#131314"
    },
});

export const themeSettings  =() => {
    const colors = tokens();

    return {
        palette: {
            primary: {
                main: colors.dkblue[500],
                contrastText: colors.white[100]
            },
            white: {
                main: colors.vistablue[500],
                contrastText: colors.white[100]
            },
            offwhite: {
                main: colors.vistablue[500],
                contrastText: colors.white[100]
            },
            vistablue: {
                main: colors.vistablue[500],
                contrastText: colors.white[100]
            },
            dimgray: {
                main: colors.vistablue[500],
                contrastText: colors.white[100]
            }
      },
      typography: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 12,
        h1: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: 40,
        },
        h2: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: 32,
        },
        h3: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: 24,
        },
        h4: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: 20,
        },
        h5: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: 16,
        },
        h6: {
          fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
          fontSize: 14,
        },
      },
    };
  };
  
  // context for color mode
  export const ColorModeContext = createContext({
    toggleColorMode: () => {},
  });
  
  export const useMode = () => {
    const [mode, setMode] = useState("dark");
  
    const colorMode = useMemo(
      () => ({
        toggleColorMode: () =>
          setMode((prev) => (prev === "light" ? "dark" : "light")),
      }),
      []
    );
  
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return [theme, colorMode];
  };

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'sans-serif'].join(','),
  },
});

export default theme;