import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://earxhhhgnpcjfqocieui.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVhcnhoaGhnbnBjamZxb2NpZXVpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcyNzE4OTcsImV4cCI6MjAyMjg0Nzg5N30.XXtrnYliuKEViJKdc24E0p3B4xdLEOhkIII4YxapLgg';

const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
  },
});

export default supabase;