import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import moment from 'moment';
import VideoThumbnail from '../components/VideoThumbnail';

const ManagerPage = () => {
    const { id } = useParams();
    const [managerData, setManagerData] = useState(null);
    const { userId, token, serverAddress } = useAuth();

    useEffect(() => {
        fetchManagerData();
    }, [id]);

    const fetchManagerData = async () => {
        try {
            const response = await fetch(`${serverAddress}managers/details/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            console.log(data);
            setManagerData(data[0]);
        } catch (error) {
            console.error('Error fetching manager data:', error);
        }
    };

    const formatDate = (timestamp) => {
        return timestamp ? moment.unix(timestamp).format('MMMM YYYY') : null;
    };

    if (!managerData) {
        return <div>Loading...</div>;
    }

    // Separate current/dated roles from previous roles
    const { currentAndDatedRoles, previousRoles } = managerData.manager_roles.reduce(
        (acc, role) => {
            if (role.start_date === null && role.end_date === null) {
                acc.previousRoles.push(role);
            } else {
                acc.currentAndDatedRoles.push(role);
            }
            return acc;
        },
        { currentAndDatedRoles: [], previousRoles: [] }
    );

    // Sort current and dated roles in reverse chronological order
    const sortedCurrentAndDatedRoles = currentAndDatedRoles.sort((a, b) => b.start_date - a.start_date);

    // Combine sorted roles with previous roles at the end
    const sortedManagerRoles = [...sortedCurrentAndDatedRoles, ...previousRoles];

    // Assuming the first role in the sorted array is the current role
    const currentRole = sortedCurrentAndDatedRoles[0];

    // Sort videos by similarity
    const sortedVideos = managerData.videos.sort((a, b) => b.similarity * b.relevance - a.similarity * a.relevance);

    return (
        <div className="manager-page">
            <header className="manager-header">
                <h1 className="manager-name">{managerData.name}</h1>
                <h2 className="manager-current-role">{currentRole.role} at {currentRole.company_name}</h2>
            </header>

            <section className="career-timeline">
                <h3 className="section-title">Career Timeline</h3>
                <div className="timeline">
                    {sortedManagerRoles.map((role, index) => (
                        <div key={role.id} className="timeline-item">
                            <div className="timeline-marker"></div>
                            <div className="timeline-content">
                                <h4 className="role-title">{role.role}</h4>
                                <p className="company-name">{role.company_name}</p>
                                {role.start_date === null && role.end_date === null ? (
                                    <p className="date-range">Previous</p>
                                ) : (
                                    <p className="date-range">
                                        {formatDate(role.start_date)} - {role.end_date ? formatDate(role.end_date) : 'Present'}
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className="related-videos">
                <h2 className="section-title">Related Videos</h2>
                <div className="video-grid">
                    {sortedVideos.map((video) => (
                        <VideoThumbnail key={video.id} video={video} />
                    ))}
                </div>
            </section>
        </div>
    );
};

export default ManagerPage;
